/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import styles from '../Crypto/Crypto.module.scss';
import RealEst from '../../Components/CryptoPlan/RealEst';
import Nav from '../../Nav/Nav';
import img from '../../images/background/estateBg.png';
import Top from '../../Components/Top/Top';
import Choose from '../../Components/Choose/Choose';
import Offer from '../../Components/Offer/Offer';
import Referral from '../../Components/Referral/Referral';
import Stats from '../../Components/Stats/Stats';
import Start from '../../Components/Start/Start';
import Deposit from '../../Components/Deposits/Deposit';
import Live from '../../Components/Live/Live';
import Ask from '../../Components/Ask/Ask';
import Sponsor from '../../Components/Sponsor/Sponsor';
import Footer from '../../Footer/Footer';

const Real = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const text = 'Explore our diverse range of investment options designed to help you achieve your financial goals.';
  return (
    <section className={styles.container}>
      <Nav />
      <Top title="Digital Real Estate" img={img} text={text} />
      <section className={`${styles.assetsDiv} ${styles.estate}`}>
        <article className={styles.header}>
          <h2 className={styles.title}> Best Investment Plans</h2>
        </article>
        <RealEst />
      </section>
      <Choose />
      <Offer />
      <Referral />
      <Stats />
      <Start />
      <Deposit />
      <Live />
      <Ask />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default Real;
