import React, { useEffect } from 'react';
import 'react-multi-carousel/lib/styles.css';
import styles from './Faqs.module.scss';
import img from '../images/background/faqBg.png';
import Nav from '../Nav/Nav';
import Top from '../Components/Top/Top';
import Ask from '../Components/Ask/Ask';
import Offer from '../Components/Offer/Offer';
import Deposit from '../Components/Deposits/Deposit';
import Live from '../Components/Live/Live';
import Sponsor from '../Components/Sponsor/Sponsor';
import Footer from '../Footer/Footer';
import Faqs from '../Components/Faqs/Faqs';

const FAQs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const text = 'Here are some of our answers to your concerns';
  return (
    <section className={styles.container}>
      <Nav />
      <Top title="FAQS" img={img} text={text} />
      <Faqs />
      <Ask />
      <Offer />
      <Deposit />
      <Live />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default FAQs;
