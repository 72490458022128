/* eslint-disable arrow-body-style */
import React from 'react';
import styles from './Live.module.scss';

const Live = () => {
  return (
    <section className={styles.container}>
      <video autoPlay muted loop className={styles.video}>
        <source src="https://static.tradingview.com/static/bundles/chart-big.a7d994603f412a3a4a52.webm" type="video/webm" />
      </video>
    </section>
  );
};

export default Live;
