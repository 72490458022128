import userData from '../Data/mock';

const generateUsernames = (count) => {
  const usernames = [];
  for (let i = 0; i < count; i += 1) {
    const randomIndex = Math.floor(Math.random() * userData.length);
    const username = userData[randomIndex];
    usernames.push(username);
  }
  return usernames;
};

export default generateUsernames;
