const reviews = [
  {
    id: 1,
    name: 'Michael Johnson',
    title: '- Real Estate Developer',
    image: 'https://res.cloudinary.com/dhyagpwyl/image/upload/v1632996460/Review%20Images/John_Doe_Review_Image_2x',
    text: "I've been a client of Green Stern for several years, and I couldn't be happier with the results. Their team consistently delivers impressive returns while also prioritizing risk management. It's reassuring to know that my investments are in capable hands.",
  },
  {
    id: 2,
    name: 'Linda Smith',
    title: '- Business Owner',
    image: 'https://res.cloudinary.com/dhyagpwyl/image/upload/v1632996460/Review%20Images/John_Doe_Review_Image_2x',
    text: "I've been a client of Green Stern for several years, and I couldn't be happier with the results. Their team consistently delivers impressive returns while also prioritizing risk management. It's reassuring to know that my investments are in capable hands.",
  },
  {
    id: 3,
    name: 'Sarah Winston',
    title: '- Investor',
    image: 'https://res.cloudinary.com/dhyagpwyl/image/upload/v1632996460/Review%20Images/John_Doe_Review_Image_2x',
    text: "As a first-time investor, I was initially hesitant, but Green Stern's team provided personalized guidance and support every step of the way. Their transparent communication and commitment to client success have exceeded my expectations.",
  },
];

export default reviews;
