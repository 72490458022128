/* eslint-disable arrow-body-style */
/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react';
// import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Sponsor.module.scss';
import sponsors from '../../Data/Sponsors';

const configure = ([desktop, tablet, mobile]) => ({
  extraLarge: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },

  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: desktop || 2,
  },

  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: tablet || 2,
  },

  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: mobile || 1,
  },
});

const Sponsors = () => {
  return (
    <section
      className={styles.sponsorsSection}
      style={{ position: 'relative' }}
    >
      <Carousel
        className={styles.logoX}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        }}
        autoPlay
        autoPlaySpeed={3000}
        infinite
        keyBoardControl
        responsive={configure([4, 4, 1])}
      >
        { sponsors.map((sponsor) => (
          <img src={sponsor} alt="sponsor logo" key={sponsor} className={styles.img} />
        )) }
      </Carousel>
    </section>
  );
};

export default memo(Sponsors);
