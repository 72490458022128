/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LuBarChartBig } from 'react-icons/lu';
import { GrTransaction } from 'react-icons/gr';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { fetchDeposits, fetchWithdrawals, fetchInvestments } from '../../redux/reducer/transactionAction';
import styles from './Transaction.module.scss';
import Nav from '../../Nav/Nav';

const Transaction = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const dispatch = useDispatch();

  const deposits = useSelector((state) => state.transactions.deposit.deposits);
  const withdrawals = useSelector((state) => state.transactions.withdrawal.withdrawals);
  const investments = useSelector((state) => state.transactions.investment.investments);
  const [selectedTransactionType, setSelectedTransactionType] = useState(deposits);

  const handleTransactionTypeChange = (e) => {
    e.preventDefault();
    if (e.target.value === 'investments') {
      dispatch(fetchInvestments());
      setSelectedTransactionType(investments);
    } else if (e.target.value === 'withdrawals') {
      dispatch(fetchWithdrawals());
      setSelectedTransactionType(withdrawals);
    } else {
      dispatch(fetchDeposits());
      setSelectedTransactionType(deposits);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfFirstTransaction = (currentPage - 1) * 5;

  const indexOfLastTransaction = currentPage * 5;

  const hasNextPage = indexOfLastTransaction < 4;

  const currentTransactions = selectedTransactionType.length > 1 ? selectedTransactionType.slice(indexOfFirstTransaction, indexOfLastTransaction) : selectedTransactionType;

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleString();
  };

  return (
    <section className={styles.container}>
      <Nav />
      <section className={styles.wallet}>
        <article className={styles.title}>
          <p className={styles.subtitle}>
            <LuBarChartBig className={styles.icon} />
            Transaction
          </p>
        </article>
        <article className={styles.walletContent} data-aos-delay="1000" data-aos="zoom-out">
          <select className={styles.select} onChange={handleTransactionTypeChange}>
            <option value="deposits">Deposit</option>
            <option value="withdrawals">Withdrawal</option>
            <option value="investments">Investment</option>
          </select>
        </article>
        <article className={styles.walletSummary} data-aos-delay="1000" data-aos="zoom-out">
          <ul className={styles.walletList}>
            {currentTransactions !== undefined && currentTransactions.map((transaction, index) => (
              <li key={index} className={styles.walletItem}>
                <div className={styles.walletItemContent}>
                  <p className={styles.walletItemTitle}>{transaction.type}</p>
                  <p className={styles.walletItemValue}>{transaction.amount}</p>
                </div>
                <div className={styles.walletItemContent}>
                  <p className={styles.walletItemTitle}>{formatDate(transaction.createdAt)}</p>
                  <GrTransaction className={styles.icon} />
                </div>
              </li>
            ))}
            {currentTransactions.length === undefined && (<li className={styles.walletItem}>No transaction yet</li>)}
          </ul>
        </article>
        <article className={styles.btnDiv}>
          <button type="button" className={styles.btn} onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          <button type="button" className={styles.btn} onClick={handleNextPage}>
            Next
          </button>
        </article>
      </section>
    </section>
  );
};

export default Transaction;
