import React from 'react';
import { FaRocketchat } from 'react-icons/fa6';
import styles from './Ask.module.scss';

const Ask = () => {
  const handleChat = () => {
    const message = 'Hello, I would like to make an enquiry.';
    window.open(`https://wa.me/${17189620540}?text=${message}`, '_blank');
  };

  return (
    <div><FaRocketchat onClick={handleChat} className={styles.scroll} /></div>
  );
};

export default Ask;
