import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PropTypes from 'prop-types';
import styles from './Top.module.scss';

const Top = (
  {
    title, img, text,
  },
) => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className={styles.topSection} style={{ overflow: 'hidden' }}>
      <img
        src={img}
        alt={img}
        className={styles.topImage}
      />
      <h1 className={styles.topHeader} data-aos="zoom-out">
        {title}
      </h1>
      <p className={styles.topText} data-aos="fade-up">
        {text}
      </p>
    </div>
  );
};

Top.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

export default Top;
