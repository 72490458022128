/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { withdrawWallet, updateAddress, fetchWallet } from '../../redux/reducer/walletAction';
import styles from './WithdrawalForm.module.scss';
import Nav from '../../Nav/Nav';

const WithdrawalForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAddresses = useSelector((state) => state.wallet.wallet.address);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [currency, setCurrency] = useState('USDT');
  const [amount, setAmount] = useState('');
  const [cryptoType, setCryptoType] = useState('');
  const [newAddress, setNewAddress] = useState('');

  const [showAddAddressForm, setShowAddAddressForm] = useState(false);

  const handleWithdrawSubmit = (e) => {
    e.preventDefault();
    dispatch(withdrawWallet({ amount, address: selectedAddress, currency }));
    dispatch(fetchWallet());
    navigate('/dashboard/wallet');
  };

  const addAddress = (e) => {
    e.preventDefault();
    dispatch(updateAddress({ cryptoType, newAddress }));
    setShowAddAddressForm(false);
  };

  return (
    <div className={styles.container} style={{ overflow: 'hidden' }}>
      <Nav />
      <h2 className={styles.header}>Withdraw Funds</h2>
      <form onSubmit={handleWithdrawSubmit} className={styles.form}>
        <div>
          <label className={styles.label}>
            Address:
            <select
              value={selectedAddress}
              onChange={(e) => setSelectedAddress(e.target.value)}
              required
              className={styles.input}
            >
              <option value="" className={styles.option}>Select Address</option>
              {Object.entries(userAddresses).map(([currency, address]) => (
                <option key={currency} value={address} className={styles.option}>
                  {currency}
                  {' '}
                  (
                  {address}
                  )
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label className={styles.label}>
            Currency:
            <select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className={styles.input}
            >
              <option value="BTC" className={styles.option}>Bitcoin (BTC)</option>
              <option value="ETH" className={styles.option}>Ethereum (ETH)</option>
              <option value="USDT" className={styles.option}>USD Tether (USDT)</option>
              <option value="BNB" className={styles.option}>Binance Coin (BNB)</option>
            </select>
          </label>
        </div>
        <div>
          <label className={styles.label}>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="0.00"
              required
              className={styles.input}
            />
          </label>
        </div>
        <button type="submit" className={styles.btn}>Withdraw</button>
        <button type="button" className={styles.btn} onClick={() => setShowAddAddressForm(true)}>
          Add Address
        </button>
      </form>
      {showAddAddressForm && (
        <section className={styles.modal}>
          <form className={styles.miniForm} onSubmit={addAddress}>
            <MdClose onClick={() => setShowAddAddressForm(false)} className={styles.exitMenu} />
            <div>
              <label className={styles.label}>
                Type:
                <select id="cyptoType" className={styles.input} onChange={(e) => setCryptoType(e.target.value)} required>
                  <option value="" className={styles.option}>Select Currency</option>
                  <option value="BTC" className={styles.option}>Bitcoin (BTC)</option>
                  <option value="ETH" className={styles.option}>Ethereum (ETH)</option>
                  <option value="USDT" className={styles.option}>USD Tether (USDT)</option>
                  <option value="BNB" className={styles.option}>Binance Coin (BNB)</option>
                </select>
              </label>
            </div>
            <div>
              <label className={styles.label}>
                Address:
                <input id="newAddress" type="text" onChange={(e) => setNewAddress(e.target.value)} className={styles.input} placeholder="Enter Address" required />
              </label>
            </div>
            <button type="submit" className={styles.btn}>Add Address</button>
          </form>
        </section>
      )}
    </div>
  );
};

export default WithdrawalForm;
