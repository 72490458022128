import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCompletedTrades,
  fetchPendingTrades,
  fetchFailedTrades,
} from '../../reducer/tradeAction';

const initialState = {
  completed: [],
  pending: [],
  failed: [],
  loading: false,
  error: null,
};

const tradeSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCompletedTrades.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchCompletedTrades.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.completed = payload.data;
    },
    [fetchCompletedTrades.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [fetchPendingTrades.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPendingTrades.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pending = payload.data;
    },
    [fetchPendingTrades.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [fetchFailedTrades.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchFailedTrades.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.failed = payload.data;
    },
    [fetchFailedTrades.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default tradeSlice.reducer;
