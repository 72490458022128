import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './Ask.module.scss';
import SecBtn from '../Button/SecBtn';

const Ask = () => {
  const navigate = useNavigate();

  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const handleStart = () => {
    if (!isAuth) {
      navigate('/register');
    } else {
      navigate('/Services');
    }
  };

  const handleQuery = () => navigate('/Faqs');

  return (
    <section className={styles.container}>
      <article className={styles.ask}>
        <h4 className={styles.text}>Got a question for us?</h4>
        <SecBtn text="Ask Us" action={handleQuery} />
      </article>
      <article className={styles.border}>
        <span className={styles.line} />
        <span className={styles.line} />
      </article>
      <article className={styles.ask}>
        <h4 className={styles.text}>Still wondering how to get started?</h4>
        <SecBtn text="Start Investing Now" action={handleStart} />
      </article>
    </section>
  );
};

export default Ask;
