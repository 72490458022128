/* eslint-disable import/extensions */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import {
//   MailerSend, EmailParams, Sender, Recipient,
// } from 'mailersend';
import { ImSpinner3 } from 'react-icons/im';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { userLogin } from '../redux/reducer/authActions';
import 'animate.css';
import styles from './Login.module.scss';
import Nav from '../Nav/Nav';

const Login = () => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      once: true,
      easing: 'ease-in',
    });
  }, []);

  // const mailerSend = new MailerSend({
  //   apiKey: 'mlsn.1f715e6f048afcdf3d644f6113ff727540ff310a863a947fe9710eb1c4bf263d',
  // });

  // const sentFrom = new Sender('support@greenstockscapital.com', 'Founder');

  const { loading, isAuthenticated } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(userLogin(formData));
    // const recipients = [
    //   new Recipient(formData[0], 'Dear Investor'),
    // ];

    // const emailParams = new EmailParams()
    //   .setFrom(sentFrom)
    //   .setTo(recipients)
    //   .setReplyTo(sentFrom)
    //   .setSubject('Successful Sign-In')
    //   .setHtml('<p>You just logged into your account. Huge profits coming loading!</p>')
    //   .setText('If this is not you, please contact us immediately');

    // await mailerSend.email.send(emailParams);

    if (isAuthenticated) {
      navigate('/');
    }
  };

  return (
    <>
      <section className={styles.container}>
        <div className={styles.lift}>
          <Nav />
        </div>
        <article className={styles.title}>
          <h1 className={styles.subtitle}>Log in to your account</h1>
          <p className={styles.desc}>Just this last step!</p>
        </article>
        <form id="login" className={styles.form} onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            name="email"
            className={styles.input}
            placeholder="Email address"
            onChange={(e) => handleInputChange(e)}
            value={formData.emailOrUsername}
            required
          />
          <input
            type="password"
            id="password"
            name="password"
            className={styles.input}
            placeholder="Password"
            onChange={(e) => handleInputChange(e)}
            value={formData.password}
            required
          />
          <button type="submit" className={styles.btn} disabled={loading}>
            { loading ? <ImSpinner3 className={styles.spinner} /> : 'Login' }
          </button>
          <p className={styles.link}>
            Don&apos;t have an account?
            <Link to="/register"> Register</Link>
          </p>
        </form>
        {isAuthenticated && (
          <article className={styles.success}>
            <iframe
              src="https://lottie.host/embed/fc537770-7799-491f-9185-f5dd6eedcb72/qh65HuyBX2.json"
              title="Welcome"
              frameBorder="none"
            />
            <p>Login successful</p>
            <Link to="/">Continue to Home</Link>
          </article>
        )}

      </section>
    </>
  );
};

export default Login;
