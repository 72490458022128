/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import refer from '../../images/refer.png';
import styles from './Referral.module.scss';

const Referral = () => {
  useEffect(() => {
    Aos.init({
      duration: 500,
      once: false,
    });
  }, []);

  return (
    <section className={styles.container} style={{ overflow: 'hidden' }}>
      <article className={styles.header}>
        <h2 className={styles.title}>Referral Program</h2>
        <div className={styles.underline} />
      </article>

      <article className={styles.content} data-aos="flip-down">
        <ul className={styles.textContent}>
          <li className={styles.text}>
            <span className={styles.list}>01</span>
            Register
          </li>
          <li className={styles.text}>
            <span className={styles.list}>02</span>
            Refer a Friend
          </li>
          <li className={styles.text}>
            <span className={styles.list}>03</span>
            Earn 10% Commission
          </li>
        </ul>
        <img src={refer} className={styles.img} alt="Team member" />
      </article>
    </section>
  );
};

export default Referral;
