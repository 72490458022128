/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineListAlt } from 'react-icons/md';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { fetchCompletedTrades, fetchPendingTrades, fetchFailedTrades } from '../../redux/reducer/tradeAction';
import styles from '../Transaction/Transaction.module.scss';
import Nav from '../../Nav/Nav';

const Trade = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const dispatch = useDispatch();

  const completed = useSelector((state) => state.trades.completed.trades);
  const pending = useSelector((state) => state.trades.pending.trades);
  const failed = useSelector((state) => state.trades.failed.trades);

  const [selectedTransactionType, setSelectedTransactionType] = useState(pending);

  const handleTransactionTypeChange = (e) => {
    e.preventDefault();
    if (e.target.value === 'Completed') {
      dispatch(fetchCompletedTrades());
      setSelectedTransactionType(completed);
    } else if (e.target.value === 'Failed') {
      dispatch(fetchFailedTrades());
      setSelectedTransactionType(failed);
    } else {
      dispatch(fetchPendingTrades());
      setSelectedTransactionType(pending);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfFirstTransaction = (currentPage - 1) * 5;

  const indexOfLastTransaction = currentPage * 5;

  const hasNextPage = indexOfLastTransaction < selectedTransactionType.length;

  const currentTransactions = selectedTransactionType.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleString();
  };

  return (
    <section className={styles.container}>
      <Nav />
      <section className={styles.wallet}>
        <article className={styles.title}>
          <p className={styles.subtitle}>
            <MdOutlineListAlt className={styles.icon} />
            Trades
          </p>
        </article>
        <article className={styles.walletContent} data-aos-delay="1000" data-aos="zoom-out">
          <select className={styles.select} onChange={handleTransactionTypeChange}>
            <option value="Pending">Active Trades</option>
            <option value="Completed">Completed Trades</option>
            <option value="Failed">Failed Trades</option>
          </select>
        </article>
        <article className={styles.walletSummary} data-aos-delay="1000" data-aos="zoom-out">
          <ul className={styles.walletList}>
            {currentTransactions !== undefined ? currentTransactions.map((transaction) => (
              <li key={transaction.id} className={styles.walletItem}>
                <div className={styles.walletItemContent}>
                  <p className={styles.walletItemTitle}>{transaction.type}</p>
                  <p className={styles.walletItemValue}>{transaction.amount}</p>
                </div>
                <div className={styles.walletItemContent}>
                  <p className={styles.walletItemTitle}>{formatDate(transaction.createdAt)}</p>
                  <p className={styles.walletItemValue}>{transaction.status}</p>
                </div>
              </li>
            )) : <li className={styles.walletItem}>No transaction yet</li>}
          </ul>
        </article>
        <article className={styles.btnDiv}>
          <button type="button" className={styles.btn} onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          <button type="button" className={styles.btn} onClick={handleNextPage}>
            Next
          </button>
        </article>
      </section>
    </section>
  );
};

export default Trade;
