import React, { useEffect } from 'react';
import styles from './How.module.scss';
import img from '../images/background/testBg.png';
import Nav from '../Nav/Nav';
import Top from '../Components/Top/Top';
import Ask from '../Components/Ask/Ask';
import Offer from '../Components/Offer/Offer';
import Deposit from '../Components/Deposits/Deposit';
import Live from '../Components/Live/Live';
import Sponsor from '../Components/Sponsor/Sponsor';
import Footer from '../Footer/Footer';
import how from '../Data/How';

const How = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const text = 'Explore our diverse range of investment options designed to help you achieve your financial goals.';
  return (
    <section className={styles.container}>
      <Nav />
      <Top title="How It Works" img={img} text={text} />
      <section className={styles.steps}>
        <h2 className={styles.title}>Simple Steps to Wealth</h2>
        <ul className={styles.list}>
          {
            how.map((item) => (
              <li key={item.id} className={styles.step}>
                <h3 className={styles.index}>{item.id}</h3>
                <h3 className={styles.stepTitle}>{item.call}</h3>
                <p className={styles.stepText}>{item.res}</p>
              </li>
            ))
}
        </ul>
      </section>
      <Ask />
      <Offer />
      <Deposit />
      <Live />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default How;
