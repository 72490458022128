const countries = [
  'Macedonia',
  'Sweden',
  'Portugal',
  'Brazil',
  'Poland',
  'China',
  'Russia',
  'Luxembourg',
  'Norway',
  'Portugal',
  'Argentina',
  'Japan',
  'Portugal',
  'Philippines',
  'Argentina',
  'Italy',
  'Poland',
  'Finland',
  'Philippines',
  'Afghanistan',
  'Dominican Republic',
  'Iran',
  'Mexico',
  'Argentina',
  'Japan',
  'Philippines',
  'Poland',
  'Philippines',
  'Tanzania',
  'Vietnam',
  'Philippines',
  'China',
  'Portugal',
  'Syria',
  'China',
  'Indonesia',
  'Indonesia',
  'Indonesia',
  'Canada',
  'Philippines',
  'China',
  'Indonesia',
  'China',
  'Indonesia',
  'Haiti',
  'Canada',
  'Czech Republic',
  'Germany',
  'Czech Republic',
  'Philippines',
  'Russia',
  'Ghana',
  'Poland',
  'China',
  'Poland',
  'France',
  'China',
  'Portugal',
  'Indonesia',
  'Brazil',
  'Israel',
  'Japan',
  'Finland',
  'Philippines',
  'Spain',
  'Indonesia',
  'Ireland',
  'Taiwan',
  'Philippines',
  'Indonesia',
  'Argentina',
  'Tanzania',
  'China',
  'Bangladesh',
  'China',
  'Namibia',
  'France',
  'Nigeria',
  'Morocco',
  'Philippines',
  'Indonesia',
  'Brazil',
  'Mexico',
  'Peru',
  'China',
  'France',
  'Greece',
  'United States',
  'Philippines',
  'Croatia',
  'China',
  'China',
  'Poland',
  'Tanzania',
  'Gabon',
  'Japan',
  'China',
  'China',
  'Japan',
  'China',
  'Denmark',
  'Philippines',
  'Mexico',
  'Portugal',
  'Saudi Arabia',
  'China',
  'Indonesia',
  'Finland',
  'Philippines',
  'Indonesia',
  'Costa Rica',
  'Poland',
  'Ukraine',
  'China',
  'United States',
  'Mongolia',
  'Dominican Republic',
  'Ukraine',
  'Georgia',
  'Peru',
  'Portugal',
  'Thailand',
  'Luxembourg',
  'China',
  'Uruguay',
  'Colombia',
  'Canada',
  'France',
  'Philippines',
  'Dominican Republic',
  'Finland',
  'Nauru',
  'Reunion',
  'Ukraine',
  'Russia',
  'Serbia',
  'China',
  'Indonesia',
  'Russia',
  'China',
  'Bangladesh',
  'Indonesia',
  'Argentina',
  'Brazil',
  'Thailand',
  'Ukraine',
  'China',
  'Colombia',
  'China',
  'Sweden',
  'Swaziland',
  'Yemen',
  'Brazil',
  'Indonesia',
  'Bangladesh',
  'Portugal',
  'Philippines',
  'China',
  'Sweden',
  'France',
  'Portugal',
  'South Korea',
  'Cuba',
  'Slovenia',
  'Peru',
  'China',
  'Mexico',
  'Brazil',
  'Indonesia',
  'Kazakhstan',
  'China',
  'Indonesia',
  'China',
  'Dominican Republic',
  'Portugal',
  'Indonesia',
  'China',
  'China',
  'Indonesia',
  'Brazil',
  'Philippines',
  'Japan',
  'China',
  'Republic of the Congo',
  'United States',
  'Indonesia',
  'Chile',
  'Macedonia',
  'Indonesia',
  'Bahrain',
  'Russia',
  'Russia',
  'Colombia',
  'Brazil',
  'Iran',
  'South Africa',
  'France',
  'Indonesia',
  'Afghanistan',
  'China',
  'Russia',
  'Poland',
  'China',
  'Indonesia',
  'Afghanistan',
  'Bahamas',
  'Philippines',
  'Indonesia',
  'Philippines',
  'Greece',
  'China',
  'China',
  'Comoros',
  'Kazakhstan',
  'Japan',
  'Thailand',
  'Vietnam',
  'France',
  'Indonesia',
  'China',
  'Colombia',
  'Portugal',
  'Philippines',
  'Russia',
  'Indonesia',
  'Botswana',
  'Ukraine',
  'Peru',
  'Vietnam',
  'Philippines',
  'Myanmar',
  'Brazil',
  'Iran',
  'France',
  'Russia',
  'Sweden',
  'China',
  'Russia',
  'Portugal',
  'Indonesia',
  'Indonesia',
  'Brazil',
  'China',
  'Colombia',
  'China',
  'Ecuador',
  'Finland',
  'Poland',
  'South Korea',
  'Poland',
  'China',
  'Sweden',
  'United States',
  'China',
  'Dominica',
  'Greece',
  'China',
  'Brazil',
  'Brazil',
  'Indonesia',
  'China',
  'France',
  'Syria',
  'China',
  'Philippines',
  'China',
  'Indonesia',
  'Philippines',
  'Russia',
  'Argentina',
  'Greenland',
  'Serbia',
  'South Africa',
  'New Zealand',
  'Indonesia',
  'France',
  'China',
  'China',
  'Indonesia',
  'Philippines',
  'China',
  'China',
  'Poland',
  'Indonesia',
  'Portugal',
  'Tunisia',
  'Cuba',
  'Japan',
  'Indonesia',
  'China',
  'Sweden',
  'Philippines',
  'Sweden',
  'Niger',
  'China',
  'Indonesia',
  'Philippines',
  'China',
  'Yemen',
  'China',
  'Philippines',
  'Philippines',
  'Panama',
  'Ireland',
  'China',
  'Chile',
  'China',
  'Mauritania',
  'Philippines',
  'Portugal',
  'Argentina',
  'Mexico',
  'Colombia',
  'China',
  'Afghanistan',
  'China',
  'Ukraine',
  'Yemen',
  'Israel',
  'Sweden',
  'Peru',
  'Japan',
  'Tanzania',
  'Russia',
  'Poland',
  'Colombia',
  'Sweden',
  'Albania',
  'Philippines',
  'Bangladesh',
  'Argentina',
  'Malaysia',
  'China',
  'Lebanon',
  'Lebanon',
  'Canada',
  'United States',
  'Brazil',
  'France',
  'Indonesia',
  'Canada',
  'Argentina',
  'China',
  'Kazakhstan',
  'Republic of the Congo',
  'Czech Republic',
  'Greece',
  'Haiti',
  'Syria',
  'Morocco',
  'Brazil',
  'China',
  'Portugal',
  'Poland',
  'Indonesia',
  'United States',
  'Nicaragua',
  'France',
  'Mexico',
  'Portugal',
  'China',
  'Venezuela',
  'El Salvador',
  'France',
  'China',
  'Bulgaria',
  'Thailand',
  'Venezuela',
  'Indonesia',
  'Brazil',
  'Portugal',
  'Moldova',
  'Brazil',
  'China',
  'Poland',
  'Russia',
  'Croatia',
  'Peru',
  'Brazil',
  'Sweden',
  'China',
  'Russia',
  'Thailand',
  'China',
  'Russia',
  'China',
  'Philippines',
  'Sweden',
  'Botswana',
  'China',
  'Indonesia',
  'Indonesia',
  'China',
  'Indonesia',
  'Indonesia',
  'Ukraine',
  'Libya',
  'Ukraine',
  'China',
  'Mongolia',
  'Mexico',
  'Cameroon',
  'Russia',
  'Ukraine',
  'Qatar',
  'Philippines',
  'China',
  'China',
  'Vietnam',
  'Philippines',
  'Brazil',
  'Russia',
  'Indonesia',
  'Russia',
  'Mexico',
  'Belarus',
  'China',
  'China',
  'Ukraine',
  'Brazil',
  'Indonesia',
  'Mongolia',
  'China',
  'Afghanistan',
  'China',
  'Madagascar',
  'Sweden',
  'Nigeria',
  'Sri Lanka',
  'Philippines',
  'Brazil',
  'Russia',
  'Canada',
  'Russia',
  'Japan',
  'Indonesia',
  'Russia',
  'China',
  'China',
  'Mexico',
  'China',
  'Greece',
  'Brazil',
  'China',
  'Indonesia',
  'Philippines',
  'Belarus',
  'Georgia',
  'Philippines',
  'Venezuela',
  'Uganda',
  'China',
  'Russia',
  'Ukraine',
  'Aland Islands',
  'Indonesia',
  'United States',
  'Tajikistan',
  'Costa Rica',
  'China',
  'China',
  'Indonesia',
  'China',
  'Greece',
  'Cuba',
  'China',
  'Laos',
  'Poland',
  'Philippines',
  'France',
  'China',
  'Indonesia',
  'United States',
  'Namibia',
  'Russia',
  'Philippines',
  'Sweden',
  'China',
  'Uganda',
  'Laos',
  'China',
  'Honduras',
  'China',
  'China',
  'Indonesia',
  'Russia',
  'Philippines',
  'France',
  'Portugal',
  'Russia',
  'New Zealand',
  'Lithuania',
  'France',
  'Russia',
  'China',
  'Bulgaria',
  'Brazil',
  'Afghanistan',
  'Bolivia',
  'China',
  'Indonesia',
  'China',
  'Bangladesh',
  'Serbia',
  'China',
  'China',
  'Argentina',
  'Dominican Republic',
  'Indonesia',
  'Russia',
  'United States',
  'China',
  'Serbia',
  'Macedonia',
  'Brazil',
  'Peru',
  'Russia',
  'Indonesia',
  'Indonesia',
  'Dominican Republic',
  'Latvia',
  'China',
  'Norway',
  'China',
  'Afghanistan',
  'Russia',
  'Peru',
  'Thailand',
  'China',
  'Serbia',
  'Portugal',
  'Serbia',
  'Sudan',
  'China',
  'Dominican Republic',
  'Belarus',
  'Peru',
  'Brazil',
  'Sweden',
  'Russia',
  'China',
  'Indonesia',
  'Canada',
  'Brazil',
  'Russia',
  'France',
  'Ukraine',
  'Indonesia',
  'Japan',
  'Nepal',
  'China',
  'China',
  'China',
  'Sweden',
  'Mexico',
  'San Marino',
  'Czech Republic',
  'France',
  'Armenia',
  'Russia',
  'Argentina',
  'China',
  'France',
  'China',
  'China',
  'Philippines',
  'Belarus',
  'France',
  'Bangladesh',
  'France',
  'Colombia',
  'Greece',
  'China',
  'China',
  'Colombia',
  'Syria',
  'China',
  'Thailand',
  'Peru',
  'China',
  'Finland',
  'Brazil',
  'France',
  'Uzbekistan',
  'United States',
  'Russia',
  'Luxembourg',
  'Colombia',
  'Greece',
  'China',
  'Philippines',
  'Poland',
  'Mozambique',
  'Japan',
  'Brazil',
  'China',
  'China',
  'Zimbabwe',
  'China',
  'South Africa',
  'Puerto Rico',
  'Belarus',
  'Mayotte',
  'Greece',
  'Indonesia',
  'Russia',
  'Vietnam',
  'China',
  'Sweden',
  'China',
  'Canada',
  'Philippines',
  'Philippines',
  'China',
  'Poland',
  'Russia',
  'Indonesia',
  'France',
  'Poland',
  'China',
  'Indonesia',
  'Russia',
  'Bahamas',
  'Indonesia',
  'China',
  'Russia',
  'Finland',
  'Poland',
  'Indonesia',
  'Japan',
  'Indonesia',
  'Portugal',
  'China',
  'Armenia',
  'Honduras',
  'Thailand',
  'China',
  'Brazil',
  'Czech Republic',
  'Thailand',
  'Russia',
  'Myanmar',
  'Indonesia',
  'United States',
  'Russia',
  'Thailand',
  'China',
  'Philippines',
  'Vietnam',
  'Pakistan',
  'Indonesia',
  'China',
  'China',
  'Peru',
  'Slovenia',
  'Syria',
  'Azerbaijan',
  'China',
  'Morocco',
  'Norway',
  'United States',
  'Greece',
  'Colombia',
  'China',
  'Mexico',
  'Indonesia',
  'Russia',
  'China',
  'Ukraine',
  'Russia',
  'Czech Republic',
  'China',
  'Denmark',
  'Japan',
  'Brazil',
  'Indonesia',
  'China',
  'Portugal',
  'Indonesia',
  'Philippines',
  'China',
  'Sierra Leone',
  'Philippines',
  'Poland',
  'Brazil',
  'Ukraine',
  'Philippines',
  'United States',
  'China',
  'Brazil',
  'Indonesia',
  'Czech Republic',
  'Portugal',
  'Mexico',
  'China',
  'Pakistan',
  'Estonia',
  'Estonia',
  'Nepal',
  'Finland',
  'Russia',
  'Portugal',
  'China',
  'Guatemala',
  'Tanzania',
  'Togo',
  'Philippines',
  'United States',
  'South Africa',
  'Tanzania',
  'Indonesia',
  'France',
  'Tanzania',
  'Spain',
  'Indonesia',
  'Canada',
  'Indonesia',
  'China',
  'Bosnia and Herzegovina',
  'China',
  'China',
  'Argentina',
  'Indonesia',
  'Portugal',
  'United Kingdom',
  'Czech Republic',
  'Dominican Republic',
  'Finland',
  'Indonesia',
  'China',
  'China',
  'Tonga',
  'United States',
  'Moldova',
  'Mongolia',
  'Haiti',
  'China',
  'France',
  'Armenia',
  'Ukraine',
  'Colombia',
  'Russia',
  'Zimbabwe',
  'Iran',
  'France',
  'China',
  'Indonesia',
  'Sweden',
  'China',
  'Canada',
  'Portugal',
  'China',
  'Slovenia',
  'Poland',
  'Albania',
  'Indonesia',
  'Ecuador',
  'Russia',
  'Philippines',
  'Russia',
  'France',
  'Indonesia',
  'Madagascar',
  'Philippines',
  'Chile',
  'Portugal',
  'Sweden',
  'France',
  'Brazil',
  'Philippines',
  'Portugal',
  'Brazil',
  'Latvia',
  'Bulgaria',
  'Colombia',
  'Portugal',
  'France',
  'China',
  'Netherlands',
  'Bangladesh',
  'Poland',
  'Poland',
  'Kazakhstan',
  'China',
  'China',
  'Czech Republic',
  'Slovenia',
  'Argentina',
  'Myanmar',
  'Norway',
  'Burkina Faso',
  'Mexico',
  'Indonesia',
  'Netherlands',
  'Ukraine',
  'Iraq',
  'China',
  'Dominican Republic',
  'Canada',
  'Philippines',
  'Russia',
  'China',
  'Bangladesh',
  'Sri Lanka',
  'Greece',
  'Uganda',
  'Philippines',
  'Philippines',
  'China',
  'China',
  'Indonesia',
  'France',
  'China',
  'Czech Republic',
  'Canada',
  'France',
  'China',
  'Philippines',
  'South Korea',
  'Brazil',
  'Colombia',
  'Malaysia',
  'Niger',
  'Indonesia',
  'Indonesia',
  'China',
  'China',
  'Brazil',
  'Brazil',
  'Guatemala',
  'Philippines',
  'Ukraine',
  'Costa Rica',
  'Serbia',
  'Philippines',
  'Central African Republic',
  'Mongolia',
  'Mexico',
  'Philippines',
  'China',
  'Indonesia',
  'Russia',
  'Tunisia',
  'Russia',
  'China',
  'China',
  'Russia',
  'Russia',
  'China',
  'Malaysia',
  'Albania',
  'China',
  'Philippines',
  'Poland',
  'Indonesia',
  'Norway',
  'China',
  'Lithuania',
  'Pakistan',
  'Nigeria',
  'Russia',
  'Philippines',
  'Argentina',
  'Portugal',
  'Indonesia',
  'Colombia',
  'China',
  'Portugal',
  'Russia',
  'Indonesia',
  'Sweden',
  'China',
  'China',
  'Philippines',
  'Brazil',
  'Thailand',
  'China',
  'Brazil',
  'Japan',
  'China',
  'Thailand',
  'Greece',
  'China',
  'Philippines',
  'Vietnam',
  'Croatia',
  'Russia',
  'Mexico',
  'China',
  'Indonesia',
  'Russia',
  'Croatia',
  'Nigeria',
  'Philippines',
  'Poland',
  'China',
  'China',
  'China',
  'China',
  'Malaysia',
  'Vietnam',
  'Russia',
  'Sweden',
  'Venezuela',
  'Russia',
  'Netherlands',
  'Philippines',
  'Democratic Republic of the Congo',
  'China',
  'United Kingdom',
  'Lesotho',
  'Czech Republic',
  'Bosnia and Herzegovina',
  'Poland',
  'Bangladesh',
  'United States',
  'Tanzania',
  'Indonesia',
  'United States',
  'Poland',
  'United States',
  'Indonesia',
  'Poland',
  'Argentina',
  'Indonesia',
  'Canada',
  'Sweden',
  'China',
  'Philippines',
  'Argentina',
  'Indonesia',
  'China',
  'Russia',
  'China',
  'Sweden',
  'Philippines',
  'Russia',
  'Kosovo',
  'Jordan',
  'China',
  'Japan',
  'United States',
  'China',
  'United States',
  'China',
  'Saudi Arabia',
  'Gambia',
  'Indonesia',
  'Indonesia',
  'Ukraine',
  'Brazil',
  'Indonesia',
  'Poland',
  'China',
  'Philippines',
  'Indonesia',
  'Cameroon',
  'United States',
  'El Salvador',
  'Croatia',
  'Thailand',
  'France',
  'Finland',
  'Croatia',
  'South Africa',
  'Germany',
  'Japan',
  'Indonesia',
  'Portugal',
  'Comoros',
  'Indonesia',
  'Armenia',
  'Philippines',
  'China',
  'Portugal',
  'Indonesia',
  'Indonesia',
  'Guatemala',
  'Indonesia',
  'China',
  'Poland',
  'China',
  'Peru',
  'Russia',
  'Italy',
  'Russia',
  'Russia',
  'China',
  'Peru',
  'China',
  'China',
  'China',
  'China',
  'China',
  'France',
  'France',
  'Portugal',
  'Greece',
  'Mauritania',
  'Canada',
];

export default countries;
