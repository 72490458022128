/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose, MdOutlineShare } from 'react-icons/md';
import { ImSpinner3 } from 'react-icons/im';
import { BitcoinPaymentButton } from 'react-bitcoin-payment-button';
import AOS from 'aos';
import fetchReferrals from '../../redux/reducer/referralAction';
import 'aos/dist/aos.css';
import styles from '../Wallet/Wallet.module.scss';
import Nav from '../../Nav/Nav';

const Referral = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({ duration: 500 });
    dispatch(fetchReferrals());
  }, []);

  const referrals = useSelector((state) => state.referrals);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <section className={styles.container}>
      <Nav />
      <section className={styles.wallet}>
        <article className={styles.title}>
          <p className={styles.subtitle}>
            <MdOutlineShare className={styles.icon} />
            Referral
          </p>
        </article>
        <article className={styles.walletContent} data-aos-delay="1000" data-aos="zoom-out">
          <p className={styles.walletTitle}>Referral Code:</p>
          <p className={styles.walletAmount}>{referrals.username}</p>
        </article>
        <article className={styles.walletSummary} data-aos-delay="1000" data-aos="zoom-out">
          <h6 className={styles.walletSummaryTitle}>Referrals</h6>
          <table className={styles.walletTable}>
            <thead>
              <tr>
                <th>Downlines</th>
              </tr>
            </thead>
            <tbody>
              { referrals.loading && <tr><td><ImSpinner3 className={styles.spinner} /></td></tr> }
              {
              referrals.referrals.length > 0 ? referrals.referrals.map((referral, index) => (
                <tr key={index}>
                  <td>{referrals.referralCode}</td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="2">No referrals found</td>
                </tr>
              )
            }
            </tbody>
          </table>
        </article>
      </section>
      {toggle && (
        <section className={styles.popWallet}>
          <MdClose onClick={handleToggle} className={styles.exitMenu} />
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$100</p>
            <BitcoinPaymentButton
              uid="0310b1d1036c4286"
              className={styles.icon}
            />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$500</p>
            <BitcoinPaymentButton uid="5c4704155b004bde" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$1,000</p>
            <BitcoinPaymentButton uid="20b5620fc54b4596" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$5,000</p>
            <BitcoinPaymentButton uid="5205ed700ea14de3" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$10,000</p>
            <BitcoinPaymentButton uid="d9d0087965f84a65" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$20,000</p>
            <BitcoinPaymentButton uid="1dfe3c3efe04486b" className={styles.icon} />
          </article>
        </section>
      )}
    </section>
  );
};

export default Referral;
