/* eslint-disable import/extensions */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Home/Home';
import About from '../About/About';
import Service from '../Services/Service';
import Crypto from '../Services/Crypto/Crypto';
import Stock from '../Services/Forex/Forex';
import Real from '../Services/Real/RealEstate';
import How from '../How/How';
import Testimonial from '../Testimonial/Testimonial';
import FAQs from '../Faqs/Faqs';
import Wallet from '../Dashboard/Wallet/Wallet';
import Transaction from '../Dashboard/Transaction/Transaction';
import Referral from '../Dashboard/Referral/Referral';
import Trade from '../Dashboard/Trade/Trade';
import WithdrawalForm from '../Dashboard/Wallet/Withdraw';

const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="/dashboard/wallet" element={<Wallet />} />
      <Route path="/dashboard/transaction" element={<Transaction />} />
      <Route path="/dashboard/referral" element={<Referral />} />
      <Route path="/dashboard/withdraw" element={<WithdrawalForm />} />
      <Route path="/trade" element={<Trade />} />
      <Route path="/About-us" element={<About />} />
      <Route path="/Services" element={<Service />} />
      <Route path="/Services/Crypto_Currency_Investment" element={<Crypto />} />
      <Route path="/Services/Forex_Trading" element={<Stock />} />
      <Route path="/Services/Digital_Real_Estates" element={<Real />} />
      <Route path="/How-it-works" element={<How />} />
      <Route path="/Testimonials" element={<Testimonial />} />
      <Route path="/Faqs" element={<FAQs />} />
    </Routes>
  );
};

export default Auth;
