import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import commit from '../../images/commitment.png';
import styles from './Commitment.module.scss';

const Commitment = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <section className={styles.container} style={{ overflow: 'hidden' }}>
      <article className={styles.header}>
        <h2 className={styles.title}>Our Commitment</h2>
        <div className={styles.underline} />
      </article>

      <article className={styles.content}>
        <article className={styles.textContent} data-aos="fade-right">
          <h3 className={styles.subtitle}>Our Mission</h3>
          <p className={styles.text}>
            At Green Stern Investment & Holdings, our mission is to maximize returns
            for our investors through strategic and diversified investment opportunities.
            We are dedicated to identifying high-potential ventures, managing risk
            effectively, and delivering superior financial results. With a focus on
            innovation, integrity, and excellence, we aim to exceed the expectations of
            our clients and stakeholders while fostering growth and prosperity in the
            global marketplace.
          </p>
          <h3 className={styles.subtitle}>Our Vision</h3>
          <p className={styles.text}>
            Our vision at Green Stern Investment & Holdings is to be a premier investment
            firm known for its unwavering commitment to excellence and exceptional returns.
            We strive to be a trusted partner for investors seeking lucrative opportunities
            cross diverse markets and asset classes. By leveraging our expertise, insights,
            and resources, we aim to set new standards of success in the investment industry
            while creating enduring value for our clients and stakeholders.
          </p>
        </article>
        <img src={commit} className={styles.img} data-aos="fade-left" alt="Team member" />
      </article>
    </section>
  );
};

export default Commitment;
