/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { TiMail, TiSocialFacebook } from 'react-icons/ti';
import { CiLocationOn } from 'react-icons/ci';
import { SlCallEnd } from 'react-icons/sl';
import styles from './Footer.module.scss';
import logoFooter from '../images/footerLogo.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <section className={styles.footerTop}>
        <article className={styles.subOne}>
          <article className={styles.logoDiv}>
            <img src={logoFooter} alt="Green Sterns Logo" className={styles.logo} />
            <small className={styles.text}>
              Green Stern Investment & Holdings was built to help you be a more confident
              investor and set you on the path to secure your financial future.
            </small>
            <div className={styles.socialLinkDiv}>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noreferrer"
                className={styles.socialLink}
              >
                <FaInstagram className={styles.icon} />
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
                className={styles.socialLink}
              >
                <FaXTwitter className={styles.icon} />
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
                title="Ask us anything on Facebook"
                className={styles.socialLink}
              >
                <TiSocialFacebook className={styles.icon} />
              </a>
            </div>
          </article>
          <article className={styles.links}>
            <article className={styles.linkOne}>
              <h4 className={styles.title}>Product</h4>
              <ul className={styles.list}>
                <li className={styles.elem}>
                  <Link to="/Services/Crypto_Currency_Investment" className={styles.link}>Crypto Currency</Link>
                </li>
                <li className={styles.elem}>
                  <Link to="/Services/Forex_Trading" className={styles.link}>Forex Trading</Link>
                </li>
                <li className={styles.elem}>
                  <Link to="/Services/Digital_Real_Estates" className={styles.link}>Real Estate</Link>
                </li>
              </ul>
            </article>
            <article className={styles.linkOne}>
              <h4 className={styles.title}>Support</h4>
              <ul className={styles.list}>
                <li className={styles.elem}>
                  <Link to="/Contact_Us" className={styles.link}>Contact Us</Link>
                </li>
                <li className={styles.elem}>
                  <Link to="/Faqs" className={styles.link}>FAQs</Link>
                </li>
                <li className={styles.elem}>
                  <Link to="/How-it-works" className={styles.link}>How it works</Link>
                </li>
                <li className={styles.elem}>
                  <Link to="/Testimonials" className={styles.link}>Testimonials</Link>
                </li>
              </ul>
            </article>
          </article>
        </article>
        <small className={styles.location}>
          <h4 className={styles.title}>Get in Touch</h4>
          <div className={styles.footerLink}>
            <CiLocationOn className={styles.icon} />
            <span className={styles.text}>
              FE1 Disney Land,
              <br />
              New York, US.
            </span>
          </div>
          <div className={styles.footerLink}>
            <SlCallEnd className={styles.icon} />
            <a href="tel:+193656110" className={styles.text} style={{ color: '#fff' }}>
              +1 (718) 962 054
            </a>
          </div>
          <div className={styles.footerLink}>
            <TiMail className={styles.icon} />
            <a
              href="mailto:info@greensternholdingsltd.com"
              className={styles.text}
              style={{ color: '#fff' }}
            >
              info@greensternholdingsltd.com
            </a>
          </div>
        </small>
      </section>
      <div className={styles.footerBottom}>
        <small className={styles.copyRight}>
          Copyright
          &copy;
          {` ${currentYear} `}
          Green Stern Investment & Holdings, All rights reserved.
        </small>
      </div>
    </footer>
  );
};

export default Footer;
