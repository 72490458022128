import { createSlice } from '@reduxjs/toolkit';
import {
  fetchDeposits,
  fetchWithdrawals,
  fetchInvestments,
} from '../../reducer/transactionAction';

const initialState = {
  deposit: {},
  withdrawal: {},
  investment: {},
  loading: false,
  error: null,
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch wallet
    [fetchDeposits.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchDeposits.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.deposit = payload.data;
    },
    [fetchDeposits.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [fetchWithdrawals.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchWithdrawals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.withdrawal = payload.data;
    },
    [fetchWithdrawals.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [fetchInvestments.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchInvestments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.investment = payload.data;
    },
    [fetchInvestments.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default transactionSlice.reducer;
