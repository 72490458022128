import crypto from '../images/crypto.png';
import forex from '../images/forex.png';
import estate from '../images/estate.png';

const offer = [
  {
    id: 1,
    img: crypto,
    topic: 'CRYPTO CURRENCY',
    intro: 'We offer crypto currency exchange services, withdrawal, deposits, token mining, airdrops, and referrals.',

  },
  {
    id: 2,
    img: forex,
    topic: 'FOREX & STOCK TRADING',
    intro: 'The foreign exchange market is a global decentralized or over-the-counter market for the trading of currencies. We also deal in raw gold and precious stone mining.',

  },
  {
    id: 3,
    img: estate,
    topic: 'DIGITAL REAL ESTATE',
    intro: 'Real estate is property consisting of land and the buildings on it, along with its natural resources such as crops, minerals or water.',

  },
];

export default offer;
