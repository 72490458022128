/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Btn.module.scss';

const PrimaryBtn = ({ text, action }) => {
  return (
    <button type="button" className={styles.primaryBtn} onClick={action}>{text}</button>
  );
};

PrimaryBtn.defaultProps = {
  text: 'Click me',
  action: () => {},
};

PrimaryBtn.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func,
};

export default PrimaryBtn;
