import { createSlice } from '@reduxjs/toolkit';
import investPlan from '../../reducer/investAction';

const initialState = {
  loading: false,
  error: null,
};

const investSlice = createSlice({
  name: 'invest',
  initialState,
  reducers: {},
  extraReducers: {
    [investPlan.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [investPlan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [investPlan.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default investSlice.reducer;
