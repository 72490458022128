import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';
import market from '../../images/market.png';
import styles from './Start.module.scss';
import PrimaryBtn from '../Button/PrimaryBtn';

const Start = () => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handleLogin = () => {
    if (isAuth) {
      navigate('/Services');
    } else {
      navigate('/Login');
    }
  };

  return (
    <section className={styles.container} data-aos="fade-in" style={{ overflow: 'hidden' }}>
      <img src={market} className={styles.img} alt="Team member" />
      <article className={styles.textContent}>
        <h3 className={styles.subtitle}>Green Stern Makes Investing EASY and PROFITABLE</h3>
        <p className={styles.text}>
          We only provide you the favorable ROI, our company was created in 2016 to help
          people around the world- from all walks of life - build a better financial
          future. Today, we are trusted to manage more assets than any other firm.
        </p>
        <article className={styles.action}>
          <PrimaryBtn text="Start Investing" action={handleLogin} />
        </article>
      </article>
    </section>
  );
};

export default Start;
