import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Testimonial.module.scss';
import img from '../images/background/teamBg.png';
import pic from '../images/testimonial.png';
import Nav from '../Nav/Nav';
import Top from '../Components/Top/Top';
import Ask from '../Components/Ask/Ask';
import Offer from '../Components/Offer/Offer';
import Deposit from '../Components/Deposits/Deposit';
import Live from '../Components/Live/Live';
import Sponsor from '../Components/Sponsor/Sponsor';
import Footer from '../Footer/Footer';
import reviews from '../Data/Review';

const Testimonial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const configure = () => ({
    extraLarge: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },

    desktop: {
      breakpoint: { max: 3000, min: 957 },
      items: 1,
    },

    tablet: {
      breakpoint: { max: 956, min: 768 },
      items: 1,
    },

    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  });

  const text = 'See what our clients have to say about us.';
  return (
    <section className={styles.container}>
      <Nav />
      <Top title="Testimonials" img={img} text={text} />
      <section className={styles.steps}>
        <article className={styles.header}>
          <p className={styles.subtitle}>
            Our delivery match our promise and that is why they all have these to say about us:
          </p>
          <img src={pic} alt="Testimonials" className={styles.pic} />
        </article>

        <article className={styles.reviewDiv}>
          <Carousel
            autoPlay
            autoPlaySpeed={5000}
            infinite
            showArrows={false}
            showDots
            className={styles.carousel}
            keyBoardControl
            responsive={configure()}
            style={{ overflow: 'hidden' }}
          >
            {reviews.map((review) => (
              <Card className={styles.reviewCard} key={review.id}>
                <Card.Body className={styles.reviewInfo}>
                  <Card.Img variant="top" src={review.image} className={styles.reviewImage} />
                  <Card.Body>
                    <Card.Title className={styles.reviewName}>{review.name}</Card.Title>
                    <div className={styles.spanner} />
                    <Card.Text className={styles.location}>{review.title}</Card.Text>
                  </Card.Body>
                </Card.Body>
                <Card.Text className={styles.reviewText}>{review.text}</Card.Text>
              </Card>
            ))}
          </Carousel>
        </article>
      </section>
      <Ask />
      <Offer />
      <Deposit />
      <Live />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default Testimonial;
