import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './About.module.scss';
import Top from '../Components/Top/Top';
import img from '../images/background/teamBg.png';
import Nav from '../Nav/Nav';
import Invest from '../Components/Invest/Invest';
import Stats from '../Components/Stats/Stats';
import Choose from '../Components/Choose/Choose';
import Offer from '../Components/Offer/Offer';
import Commitment from '../Components/Commitment/Commitment';
import Referral from '../Components/Referral/Referral';
import Recommendation from '../Components/Recommendation';
import Sponsor from '../Components/Sponsor/Sponsor';
import Footer from '../Footer/Footer';

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const text = 'Join our community to share experiences, gain valuable insights, and build a network for successful investing.';

  return (
    <section className={styles.about}>
      <Nav />
      <Top title="About Us" img={img} text={text} />
      <Invest />
      <Stats />
      <Choose />
      <Offer />
      <Commitment />
      <Referral />
      <Recommendation />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default About;
