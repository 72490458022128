/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/* eslint-disable import/extensions */
import React from 'react';
import CountUp from 'react-countup';
import stats from '../../Data/Stats';
import styles from './Stats.module.scss';

const Stats = () => {
  return (
    <section className={styles.stats}>
      {stats.map((stat) => (
        <article key={stat.id} className={styles.stat}>
          <img src={stat.icon} alt={stat.title} className={styles.icon} />
          <h4 className={styles.number}>
            { stat.title === 'Total Deposits' || stat.title === 'Total Payouts' ? '$' : ''}
            <CountUp start={0} end={stat.number} separator="," decimal="." duration={60} />
          </h4>
          <p className={styles.text}>{stat.title}</p>
        </article>
      ))}
    </section>
  );
};

export default Stats;
