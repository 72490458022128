import { createSlice } from '@reduxjs/toolkit';
import { fetchWallet, withdrawWallet, updateAddress } from '../../reducer/walletAction';

const initialState = {
  wallet: {},
  withdraw: {},
  update: {},
  loading: false,
  error: null,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch wallet
    [fetchWallet.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchWallet.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.wallet = payload.data;
    },
    [fetchWallet.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [withdrawWallet.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.withdraw = payload.data;
    },
    [updateAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.update = payload.data;
    },
  },
});

export default walletSlice.reducer;
