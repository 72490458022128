/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import styles from '../Home/Home.module.scss';
import CryptoPlan from '../Components/CryptoPlan/CryptoPlan';
import RealEst from '../Components/CryptoPlan/RealEst';
import Forex from '../Components/CryptoPlan/Forex';
import Nav from '../Nav/Nav';
import img from '../images/background/currencyBg.png';
import Top from '../Components/Top/Top';
import Choose from '../Components/Choose/Choose';
import Offer from '../Components/Offer/Offer';
import Referral from '../Components/Referral/Referral';
import Stats from '../Components/Stats/Stats';
import Start from '../Components/Start/Start';
import Deposit from '../Components/Deposits/Deposit';
import Live from '../Components/Live/Live';
import Ask from '../Components/Ask/Ask';
import Sponsor from '../Components/Sponsor/Sponsor';
import Footer from '../Footer/Footer';

const Service = () => {
  const [isCrypto, setIsCrypto] = useState(true);
  const [isReal, setIsReal] = useState(false);
  const [isForex, setIsForex] = useState(false);

  const text = 'Explore our diverse range of investment options designed to help you achieve your financial goals.';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCrypto = () => {
    setIsCrypto(true);
    setIsReal(false);
    setIsForex(false);
  };

  const handleReal = () => {
    setIsReal(true);
    setIsCrypto(false);
    setIsForex(false);
  };

  const handleForex = () => {
    setIsForex(true);
    setIsCrypto(false);
    setIsReal(false);
  };

  const classify = () => {
    if (isCrypto) {
      return styles.crypto;
    }
    if (isReal) {
      return styles.real;
    }
    return styles.forex;
  };
  return (
    <section className={styles.container}>
      <Nav />
      <Top title="Services" img={img} text={text} />
      <section className={`${styles.assetsDiv} ${classify()}`}>
        <article className={styles.header}>
          <h2 className={styles.title}>Investment Plans</h2>
          <div className={styles.underline} />

          <p className={styles.subtitle}>
            Explore our diverse range of investment options designed to help you
            achieve your financial goals.
          </p>
          <div className={styles.flipper}>
            <button type="button" className={`${styles.Btn} ${isCrypto && styles.active}`} onClick={handleCrypto}>Crypto</button>
            <button type="button" className={`${styles.Btn} ${isReal && styles.active}`} onClick={handleReal}>Real Estate</button>
            <button type="button" className={`${styles.Btn} ${isForex && styles.active}`} onClick={handleForex}>Forex</button>
          </div>
        </article>
        {isCrypto && <CryptoPlan />}
        {isReal && <RealEst />}
        {isForex && <Forex />}
      </section>
      <Choose />
      <Offer />
      <Referral />
      <Stats />
      <Start />
      <Deposit />
      <Live />
      <Ask />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default Service;
