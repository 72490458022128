const faqs = [
  {
    id: 1,
    question: 'What is the minimum amount per deposit?',
    answer: 'We want to maximize profit on every penny you put in which is why the minimum deposit amount is $200. You can open transactions on the platform starting with just $200.',
  },
  {
    id: 2,
    question: 'How long does it take to withdraw funds?',
    answer: 'We aim to process all withdrawal requests within 1 hour. Please keep in mind that the payment service provider may require more time to complete the process depending on the payment method.',
  },
  {
    id: 3,
    question: 'Is there a limit on withdraw per day?',
    answer: 'You can withdraw up to $100,000 per day. The number of withdrawal requests is unlimited. The withdrawal request must not exceed the amount available on your account.',
  },
  {
    id: 4,
    question: 'I forgot my password. What should I do?',
    answer: 'When you sign in to the site, you can click the "Forgot your password?" link and enter the email address you used for registration. You will get an email message with a link for setting a new password.',
  },
  {
    id: 5,
    question: 'How do I withdraw money?',
    answer: 'To withdraw your money, go to Withdraw Funds. Choose a method, enter the amount and other information, and click "Withdraw Funds". Your request will be handled within 1 hour.',
  },
  {
    id: 6,
    question: 'Do I need to provide any documents to make a withdrawal?',
    answer: 'Yes. You need to verify your identity in order to withdraw funds. Account verification is necessary in order to prevent fraudulent financial transactions on the account.',
  },
  {
    id: 7,
    question: 'What are the minimum and maximum withdrawal amounts?',
    answer: 'We have no restrictions as to the minimum withdrawal amount. The maximum allowable withdrawal amount is $5,000,000.',
  },
];

export default faqs;
