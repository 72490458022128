import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PropTypes from 'prop-types';
import styles from './Invest.module.scss';
import aboutImg from '../../images/aboutImg.png';

const Invest = ({ home }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  return (
    <section className={styles.container} style={{ overflow: 'hidden' }}>
      {home === true && (
        <article className={styles.header}>
          <h2 className={styles.title}>About Us</h2>
          <div data-aos="fade-down-right" className={styles.underline} />
        </article>
      )}
      <article className={styles.content}>
        <img src={aboutImg} alt="About Us" data-aos="flip-right" className={styles.aboutImg} />
        <article className={styles.textContent}>
          <h3 className={styles.subtitle} data-aos="zoom-in-up">Invest With Us, With Confidence!</h3>
          <p className={styles.text} data-aos="zoom-in-up">
            Greeenstern Holdings is a global investment company that offers a range of
            financial services to clients across the world. We provide a solid
            foundation for our clients to grow their wealth and achieve their financial
            goals. Our team of experts is dedicated to providing the best financial
            services to our clients and helping them achieve financial freedom. We
            offer a range of investment options, including cryptocurrency, forex, and
            stocks, to help our clients grow their wealth and secure their financial
            future.
          </p>
          <p className={styles.text} data-aos="zoom-in-up">
            We are the ultimate destination for investors seeking lucrative investment
            opportunities in a secure and user-friendly environment. Whether you&apos;re a
            seasoned investor or just getting started, our platform empowers you to
            grow your wealth with confidence like other happy 70,095 investors.
          </p>
          <p className={styles.text} data-aos="zoom-in-up">
            Our goal is to provide the best financial services to our clients and help
            them achieve their financial goals. Whether you&apos;re looking for short-term
            gains or long-term growth, our platform has the perfect solution for your
            ROI expectations.Start your journey towards financial freedom today.
          </p>
        </article>
      </article>
    </section>
  );
};

Invest.defaultProps = {
  home: false,
};

Invest.propTypes = {
  home: PropTypes.bool,
};

export default Invest;
