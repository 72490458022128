/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import styles from '../Crypto/Crypto.module.scss';
import Forex from '../../Components/CryptoPlan/Forex';
import Nav from '../../Nav/Nav';
import img from '../../images/background/forexBg.png';
import Top from '../../Components/Top/Top';
import Choose from '../../Components/Choose/Choose';
import Offer from '../../Components/Offer/Offer';
import Referral from '../../Components/Referral/Referral';
import Stats from '../../Components/Stats/Stats';
import Start from '../../Components/Start/Start';
import Deposit from '../../Components/Deposits/Deposit';
import Live from '../../Components/Live/Live';
import Ask from '../../Components/Ask/Ask';
import Sponsor from '../../Components/Sponsor/Sponsor';
import Footer from '../../Footer/Footer';

const Stock = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const text = 'Explore our diverse range of investment options designed to help you achieve your financial goals.';

  return (
    <section className={styles.container}>
      <Nav />
      <Top title="Forex Trading" img={img} text={text} />
      <section className={`${styles.assetsDiv} ${styles.forex}`}>
        <article className={styles.header}>
          <h2 className={styles.title}> Best Investment Plans</h2>
        </article>
        <Forex />
      </section>
      <Choose />
      <Offer />
      <Referral />
      <Stats />
      <Start />
      <Deposit />
      <Live />
      <Ask />
      <Sponsor />
      <Footer />
    </section>
  );
};

export default Stock;
