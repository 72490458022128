/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import styles from './Notification.module.scss';
import generateUsernames from './usernames';
import countries from '../Data/mockCountries';

const GlobalNotification = () => {
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const notifications = [];

  const generateRandomAmount = () => {
    const randomAmount = (Math.random() * 50000).toFixed(2);
    return parseFloat(randomAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const generateNotifications = async () => {
    const usernames = generateUsernames(1000);

    for (let i = 0; i < 1000; i += 1) {
      const randomIndex = Math.floor(Math.random() * countries.length);
      const randomCountry = countries[randomIndex];
      const randomUsername = usernames[i];
      const randomAmount = generateRandomAmount();

      const investmentMessage = `${randomUsername} from ${randomCountry} just invested ${randomAmount}.`;
      const withdrawalMessage = `${randomUsername} from ${randomCountry} just withdrew ${randomAmount}.`;

      notifications.push(investmentMessage);
      notifications.push(withdrawalMessage);
    }
    return notifications;
  };

  useEffect(() => {
    const displayNotification = async () => {
      const notifications = await generateNotifications();
      const randomIndex = Math.floor(Math.random() * notifications.length);
      const randomNotification = notifications[randomIndex];
      setMessage(randomNotification);

      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);
      }, 4000);
    };

    displayNotification();

    const interval = setInterval(() => {
      displayNotification();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`${styles.notification} ${isVisible ? styles.visible : ''}`}>
      {message && (
        <div className={styles.notificationContent}>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default GlobalNotification;
