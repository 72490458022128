import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import baseUrl from '../baseUrl';

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseUrl}/login`,
        { email, password },
        config,
      );

      localStorage.setItem('token', data.token);
      toast.success('Login successful');
      return data;
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 400) {
        toast.error('Invalid Email or Password');
      } else {
        toast.error('Check your internet connection and try again');
      }
      return rejectWithValue(error.message);
    }
  },
);

export const registerUser = createAsyncThunk(
  'auth/register',
  async ({
    name, username, country, email, password, referee,
  }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseUrl}/register`,
        {
          name, username, country, email, password, referee,
        },
        config,
      );
      toast.success('Registration was successful');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error('Something is not right. Try again later.');
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const userLogout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      toast.success('Hope to see you again soon!');
      return 'User logged out successfully';
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token, authorization denied');
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(`${baseUrl}/user`, config);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({
    name, username, country, email, password,
  }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token, authorization denied');
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.put(
        `${baseUrl}/user`,
        {
          name, username, country, email, password,
        },
        config,
      );

      localStorage.setItem('userInfo', JSON.stringify(data));
      toast.success('User details updated successfully');
      return data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error('User already exists. Please choose a different username.');
      }
      toast.error('Check details and try again!');
      return rejectWithValue(error.message);
    }
  },
);
