/* eslint-disable import/extensions */
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import walletReducer from '../features/auth/walletSlice';
import transactionReducer from '../features/auth/transactionSlice';
import tradesReducer from '../features/user/tradeSlice';
import referralReducer from '../features/user/referralSlice';
import investReducer from '../features/user/investSlice';

const token = localStorage.getItem('token');

const initialState = {
  auth: {
    token: token || null,
    isAuthenticated: !!token,
    success: false,
  },
  wallet: {
    wallet: {
      balance: {
        BTC: 0,
        ETH: 0,
        USDT: 0,
        BNB: 0,
      },
      address: [],
      totalBalance: 0,
    },
    withdraw: {},
    update: {},
  },
  transactions: {
    deposit: [],
    withdrawal: [],
    investment: [],
  },
  trades: {
    completed: [],
    pending: [],
    failed: [],
    loading: false,
    error: null,
  },
  referrals: {
    referrals: [],
    username: '',
  },
  invest: {
    loading: false,
    error: null,
  },
};

const rootReducer = combineReducers({
  auth: authReducer,
  wallet: walletReducer,
  transactions: transactionReducer,
  trades: tradesReducer,
  referrals: referralReducer,
  invest: investReducer,
});

export default configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
});
