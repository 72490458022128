import { createSlice } from '@reduxjs/toolkit';
import fetchReferrals from '../../reducer/referralAction';

const initialState = {
  referrals: [],
  username: '',
  loading: false,
  error: null,
};

const referralSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch wallet
    [fetchReferrals.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchReferrals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.referrals = payload.data.referrals;
      state.username = payload.data.username;
    },
    [fetchReferrals.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default referralSlice.reducer;
