/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styles from './Deposit.module.scss';
import { withdraw, deposit } from '../../Data/Transact';

const Deposit = () => {
  const [withdrawalRows, setWithdrawalRows] = useState([]);
  const [depositRows, setDepositRows] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const generateRows = (data) => {
    const endIndex = currentIndex + 5 > data.length ? data.length : currentIndex + 5;
    return data.slice(currentIndex, endIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 5 >= withdraw.length ? 0 : prevIndex + 5));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setWithdrawalRows(generateRows(withdraw));
    setDepositRows(generateRows(deposit));
  }, [currentIndex]);

  const renderRows = (rows) => rows.map((transaction) => (
    <tr key={transaction.id}>
      <td>{transaction.user}</td>
      <td>{transaction.currency}</td>
      <td>{transaction.amount}</td>
    </tr>
  ));

  return (
    <section className={styles.container}>
      <article className={styles.header}>
        <h2 className={styles.title}>Withdrawals & Deposits History</h2>
        <div className={styles.underline} />
      </article>

      <article className={styles.content}>
        <div className={styles.tableDiv}>
          <h2>Latest Withdrawals</h2>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Currency</th>
                <th>Value ($)</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(withdrawalRows)}
            </tbody>
          </table>
        </div>

        <div className={styles.tableDiv}>
          <h2>Latest Deposits</h2>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Currency</th>
                <th>Value ($)</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(depositRows)}
            </tbody>
          </table>
        </div>
      </article>
    </section>
  );
};

export default Deposit;
