/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from 'react-icons/md';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Faqs.module.scss';
import faqs from '../../Data/Faqs';
import img from '../../images/faqs.png';

const Faqs = ({ home }) => {
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const [isOpen, setIsOpen] = useState(null);

  const handleToggle = (index) => {
    setIsOpen((prevOpenIndex) => (prevOpenIndex === index ? null : index));
  };
  return (
    <section id="faqs" className={styles.faqContainer} style={{ overflow: 'hidden' }}>
      {
        home && (
          <article className={styles.header}>
            <h2 className={styles.title}>
              Frequently Asked Questions
            </h2>
            <div className={styles.underline} />
          </article>
        )
      }
      <article className={styles.faqContent}>
        <img src={img} alt="Faqs" className={styles.img} />
        <article className={styles.queries}>
          {
            faqs.map((item) => (
              <article className={styles.faq} key={item.id} data-aos="fade-up">
                <p className={styles.faqTitle} onClick={() => handleToggle(item.id)}>
                  {isOpen === item.id
                    ? <MdOutlineArrowDropDown className={styles.flip} />
                    : <MdOutlineArrowRight className={styles.flip} />}
                  {item.question}
                </p>
                { isOpen === item.id && <p className={styles.faqAnswer} data-aos="fade-right">{item.answer}</p> }
              </article>
            ))
          }
        </article>
      </article>
    </section>
  );
};

Faqs.defaultProps = {
  home: false,
};

Faqs.propTypes = {
  home: PropTypes.bool,
};

export default Faqs;
