import users from '../images/icons/icon_member.svg';
import deposits from '../images/icons/icon_depo.svg';
import pay from '../images/icons/icon_pay.svg';
import countries from '../images/icons/icon_country.svg';

const stats = [
  {
    id: 1,
    title: 'Total Users',
    number: 70095,
    icon: users,
  },
  {
    id: 2,
    title: 'Total Deposits',
    number: 80068118.65,
    icon: deposits,
  },
  {
    id: 3,
    title: 'Total Payouts',
    number: 3004441095.9,
    icon: pay,
  },
  {
    id: 4,
    title: 'Member Countries',
    number: 80,
    icon: countries,
  },
];

export default stats;
