import { createSlice } from '@reduxjs/toolkit';
import { userLogin, registerUser, userLogout } from '../../reducer/authActions';

const token = localStorage.getItem('token');

const initialState = {
  loading: false,
  isAuthenticated: false,
  token,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.token = payload.token;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // logout user
    [userLogout.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [userLogout.fulfilled]: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.token = null;
    },
    [userLogout.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default authSlice.reducer;
