import binance from '../images/sponsors/binance.png';
import coincap from '../images/sponsors/coincap.png';
import ethereum from '../images/sponsors/ethereum.png';
import card from '../images/sponsors/mastercard.png';
import chainlink from '../images/sponsors/chainlink.png';
import housing from '../images/sponsors/housing.png';

const sponsors = [
  binance,
  coincap,
  ethereum,
  card,
  chainlink,
  housing,
];

export default sponsors;
