import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import baseUrl from '../baseUrl';

const fetchReferrals = createAsyncThunk(
  'referrals/fetchReferrals',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token, authorization denied');
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const data = await axios.get(`${baseUrl}/referrals`, config);
      return data;
    } catch (error) {
      // toast.error('Let us try again, please!');
      return rejectWithValue(error.message);
    }
  },
);

export default fetchReferrals;
