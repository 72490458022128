import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Choose.module.scss';
import choose from '../../Data/Choose';

const Choose = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in',
      once: true,
    });
  }, []);
  return (
    <section className={styles.container} style={{ overflow: 'hidden' }}>
      <article className={styles.header}>
        <h2 className={styles.title}>Why Choose Us</h2>
        <div className={styles.underline} />
      </article>

      <article className={styles.content}>
        <article className={styles.textContent} data-aos="fade-right">
          <h3 className={styles.subtitle}>Maximize Your Wealth With Strategic Investments</h3>
          <p className={styles.text}>
            We have a team of professional and qualified financial analysts who have developed
            new strategies as they leverage access to premium analytical software and AI tools
            to reduce your risk to
            <span className={styles.bold}> ZERO. </span>
            We guarantee your 100% satisfaction for investing with us!
          </p>
          <div className={styles.barDiv}>
            <p className={styles.boldText}>Flexibility</p>
            <div className={styles.bar} data-aos="fade-up-right" />
          </div>
          <div className={styles.barDiv}>
            <p className={styles.boldText}>Expert Advisors</p>
            <div className={styles.bar} data-aos="fade-down-right" />
          </div>
          <div className={styles.barDiv}>
            <p className={styles.boldText}>Accuracy</p>
            <div className={styles.bar} data-aos="fade-up-left" />
          </div>
          <div className={styles.barDiv}>
            <p className={styles.boldText}>Accessibility</p>
            <div className={styles.bar} data-aos="fade-up-right" />
          </div>
        </article>
        <article className={styles.perksDiv}>
          {choose.map((perk) => (
            <article className={styles.perk} data-aos-delay="300" data-aos="flip-left" key={perk.id}>
              <img src={perk.icon} className={styles.icon} alt={perk.title} />
              <div className={styles.div}>
                <h5 className={styles.heading}>{perk.title}</h5>
                <p className={styles.text}>{perk.text}</p>
              </div>
            </article>
          ))}
        </article>
      </article>
    </section>
  );
};

export default Choose;
