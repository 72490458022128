const withdraw = [
  {
    id: 1,
    user: 'Aba9ice',
    currency: 'USDT',
    amount: '3,600',
  },
  {
    id: 2,
    user: 'DubaiHat',
    currency: 'BTC',
    amount: '12,005.5',
  },
  {
    id: 3,
    user: 'Debbyking_one',
    currency: 'BNB',
    amount: '460,007.31',
  },
  {
    id: 4,
    user: 'Harvey',
    currency: 'BTC',
    amount: '50',
  },
  {
    id: 5,
    user: 'Jamie',
    currency: 'USDT',
    amount: '3,900',
  },
  {
    id: 6,
    user: 'Mafia_GOAT',
    currency: 'ETH',
    amount: '10,500',
  },
  {
    id: 7,
    user: 'MindDB',
    currency: 'BNB',
    amount: '779,000',
  },
  {
    id: 8,
    user: 'Justin_Piper',
    currency: 'BTC',
    amount: '550',
  },
  {
    id: 9,
    user: 'Cashcow',
    currency: 'USDT',
    amount: '3,700',
  },
  {
    id: 10,
    user: 'Beyoncer',
    currency: 'USDT',
    amount: '1,500,000',
  },
  {
    id: 11,
    user: 'G_one',
    currency: 'BNB',
    amount: '400,000',
  },
  {
    id: 12,
    user: 'Harvey_one',
    currency: 'BTC',
    amount: '5,000',
  },
  {
    id: 13,
    user: 'Aba1',
    currency: 'USDT',
    amount: '300',
  },
  {
    id: 32,
    user: 'Mafia',
    currency: 'ETH',
    amount: '10,500',
  },
  {
    id: 14,
    user: 'Scottie',
    currency: 'BNB',
    amount: '779,000',
  },
  {
    id: 15,
    user: 'Justincash',
    currency: 'BTC',
    amount: '5,500',
  },
  {
    id: 16,
    user: 'Felix',
    currency: 'ETH',
    amount: '3,600',
  },
  {
    id: 17,
    user: 'KaffyBest',
    currency: 'BTC',
    amount: '11,000',
  },
  {
    id: 18,
    user: 'Debbyking',
    currency: 'BNB',
    amount: '40',
  },
  {
    id: 19,
    user: 'Jessica',
    currency: 'BTC',
    amount: '500',
  },
  {
    id: 20,
    user: 'JoniSonow',
    currency: 'USDT',
    amount: '3,600,000',
  },
  {
    id: 21,
    user: 'Jayson',
    currency: 'ETH',
    amount: '1,050.80',
  },
  {
    id: 22,
    user: 'MindDB',
    currency: 'BNB',
    amount: '779,000',
  },
  {
    id: 23,
    user: 'Berger',
    currency: 'BTC',
    amount: '5,850',
  },
  {
    id: 24,
    user: 'Cashcow',
    currency: 'USDT',
    amount: '3,708',
  },
  {
    id: 25,
    user: 'Examiner',
    currency: 'USDT',
    amount: '1,500',
  },
  {
    id: 26,
    user: 'PokerBot',
    currency: 'ETH',
    amount: '40,000',
  },
  {
    id: 27,
    user: 'Agnessne',
    currency: 'BTC',
    amount: '6,789',
  },
  {
    id: 28,
    user: 'ABMoney',
    currency: 'USDT',
    amount: '3,200',
  },
  {
    id: 29,
    user: 'MaryKay',
    currency: 'ETH',
    amount: '10,500',
  },
  {
    id: 30,
    user: 'Kotlin',
    currency: 'BNB',
    amount: '77,005.71',
  },
  {
    id: 31,
    user: 'Justincash',
    currency: 'BTC',
    amount: '185,505',
  },
  {
    id: 33,
    user: 'Karl',
    currency: 'ETH',
    amount: '11,600',
  },
  {
    id: 34,
    user: 'Sheikh',
    currency: 'BNB',
    amount: '779,000',
  },
  {
    id: 35,
    user: 'Stevie',
    currency: 'BTC',
    amount: '5,500',
  },
];

const deposit = [
  {
    id: 1,
    user: 'Cashcow',
    currency: 'USDT',
    amount: '30,619',
  },
  {
    id: 2,
    user: 'Dubait',
    currency: 'BTC',
    amount: '12,005.5',
  },
  {
    id: 3,
    user: 'Dannybiz',
    currency: 'BNB',
    amount: '230,901',
  },
  {
    id: 4,
    user: 'Harvey',
    currency: 'BTC',
    amount: '5,029',
  },
  {
    id: 5,
    user: 'Cranker',
    currency: 'USDT',
    amount: '100,000',
  },
  {
    id: 6,
    user: 'Mafia_',
    currency: 'ETH',
    amount: '7,810',
  },
  {
    id: 7,
    user: 'Stockist',
    currency: 'BNB',
    amount: '77,000',
  },
  {
    id: 8,
    user: 'Jotabay',
    currency: 'BTC',
    amount: '55',
  },
  {
    id: 9,
    user: 'ZeusSheikh',
    currency: 'USDT',
    amount: '9,500',
  },
  {
    id: 10,
    user: 'Smallfry',
    currency: 'USDT',
    amount: '1,500,000',
  },
  {
    id: 11,
    user: 'Turner',
    currency: 'BTC',
    amount: '250,001',
  },
  {
    id: 12,
    user: 'Jesusdel',
    currency: 'BTC',
    amount: '1,080',
  },
  {
    id: 13,
    user: 'Superman',
    currency: 'USDT',
    amount: '30,200,700',
  },
  {
    id: 32,
    user: 'Mascara1',
    currency: 'ETH',
    amount: '11,600',
  },
  {
    id: 14,
    user: 'Scottie',
    currency: 'BNB',
    amount: '1,900,211.34',
  },
  {
    id: 15,
    user: 'Talknow',
    currency: 'BTC',
    amount: '9,300',
  },
  {
    id: 16,
    user: 'Felix',
    currency: 'ETH',
    amount: '3,960',
  },
  {
    id: 17,
    user: 'Katrina',
    currency: 'BTC',
    amount: '15,000',
  },
  {
    id: 18,
    user: 'DakeJake',
    currency: 'BNB',
    amount: '40,000',
  },
  {
    id: 19,
    user: 'Louis',
    currency: 'BTC',
    amount: '4,210',
  },
  {
    id: 20,
    user: 'BonAppetit',
    currency: 'USDT',
    amount: '36,000',
  },
  {
    id: 21,
    user: 'JayOne',
    currency: 'ETH',
    amount: '1,050.80',
  },
  {
    id: 22,
    user: 'Lawson',
    currency: 'BNB',
    amount: '77,000',
  },
  {
    id: 23,
    user: 'Broker',
    currency: 'BTC',
    amount: '776',
  },
  {
    id: 24,
    user: 'Trevor',
    currency: 'USDT',
    amount: '3,508',
  },
  {
    id: 25,
    user: 'Al-samad',
    currency: 'USDT',
    amount: '101,500,000',
  },
  {
    id: 26,
    user: 'UsmanHolt',
    currency: 'ETH',
    amount: '489,500',
  },
  {
    id: 27,
    user: 'Billygenie',
    currency: 'BTC',
    amount: '61,359',
  },
  {
    id: 28,
    user: 'PotheadHottie',
    currency: 'USDT',
    amount: '3,200',
  },
  {
    id: 29,
    user: 'Connor',
    currency: 'ETH',
    amount: '12,810',
  },
  {
    id: 30,
    user: 'WealthnoiseEast',
    currency: 'USDT',
    amount: '7,000,502,800',
  },
  {
    id: 31,
    user: 'Babyish',
    currency: 'BTC',
    amount: '11,005',
  },
  {
    id: 33,
    user: 'Stonewall',
    currency: 'BNB',
    amount: '1,600',
  },
  {
    id: 34,
    user: 'James',
    currency: 'ETH',
    amount: '17,900',
  },
  {
    id: 35,
    user: 'Jeffrey',
    currency: 'BTC',
    amount: '9,800',
  },
];

export { withdraw, deposit };
