/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import {
  FaFacebookF, FaTwitter, FaInstagram, FaUserCircle,
} from 'react-icons/fa';
import {
  MdMail, MdPhoneIphone, MdOutlineShare, MdOutlineListAlt, MdLogout, MdMenu, MdClose,
} from 'react-icons/md';
import { CiWallet } from 'react-icons/ci';
import { LuBarChartBig } from 'react-icons/lu';
import { userLogout } from '../redux/reducer/authActions';
import logo from '../images/mainLogo.svg';
// import TradingViewWidget from '../Components/Live/Update';
import styles from './Nav.module.scss';

const Nav = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedService, setSelectedService] = useState('');
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const wallet = useSelector((state) => state.wallet.wallet);

  const handleServiceChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedService(selectedValue);
    navigate(selectedValue);
  };

  const [toggle, setToggle] = useState(false);
  const [sub, setSub] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    await dispatch(userLogout());
    navigate('/');
  };

  const handleMenuOpen = () => {
    setToggle(!toggle);
  };

  const handleSub = () => {
    setSub(!sub);
  };

  const handleMenuClose = () => {
    setToggle(false);
  };

  const formattedBalance = (balance) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(balance);
  };

  return (
    <header className={styles.header}>
      <section className={styles.topNav} data-aos="fade-down">
        <article className={styles.firstSec}>
          <div className={styles.mail}>
            <MdMail />
            <a href="mailto:info@greeensternholdings.com" className={styles.mailText}>info@greeensternholdings.com</a>
          </div>
          <div className={styles.mail}>
            <MdPhoneIphone />
            <a href="tel:+910567445234" className={styles.phoneText}>+91-056-744-5234</a>
          </div>

          <select className={styles.language}>
            <option value="en">EN</option>
            <option value="es">ES</option>
          </select>

          {isAuth && (
            <div className={styles.secSec}>
              <CiWallet className={styles.icon} />
              <span className={styles.wallet}>
                {' '}
                {formattedBalance(wallet.totalBalance)}
              </span>
            </div>
          )}
        </article>

        <article className={styles.secondSec}>
          <div className={styles.social}>
            <a href="https://www.facebook.com" data-aos="zoom-out" className={styles.socialLink}><FaFacebookF /></a>
            <a href="https://www.twitter.com" data-aos-duration="600" data-aos="zoom-out" className={styles.socialLink}><FaTwitter /></a>
            <a href="https://www.instagram.com" data-aos-duration="800" data-aos="zoom-in" className={styles.socialLink}><FaInstagram /></a>
            {isAuth && <FaUserCircle className={styles.socialLink} onClick={handleSub} /> }
          </div>
          { sub && (
            <div className={styles.auth} data-aos-duration="1000" data-aos="fade-left">
              <ul className={styles.authList}>
                <li className={styles.authItem}>
                  <Link to="/dashboard/wallet" className={styles.authLink}>
                    <CiWallet className={styles.icon} />
                    Wallet
                  </Link>
                </li>
                <li className={styles.authItem}>
                  <Link to="/dashboard/transaction" className={styles.authLink}>
                    <LuBarChartBig className={styles.icon} />
                    Transaction
                  </Link>
                </li>
                <li className={styles.authItem}>
                  <Link to="/dashboard/referral" className={styles.authLink}>
                    <MdOutlineShare className={styles.icon} />
                    Referrals
                  </Link>
                </li>
                <li className={styles.authItem}>
                  <Link to="/trade" className={styles.authLink}>
                    <MdOutlineListAlt className={styles.icon} />
                    Trade
                  </Link>
                </li>
                <li className={styles.authItem} onClick={handleLogout}>
                  <button type="button" className={styles.authLink}>
                    <MdLogout className={styles.icon} />
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          )}
        </article>
      </section>
      <nav className={`${styles.nav} ${isScrolled ? styles.scrolled : ''}`}>
        <Link to="/" className={styles.logo}>
          <img
            src={logo}
            alt="Greeenstern Investment & Holdings"
            className={styles.logoImg}
          />
        </Link>

        {
          toggle ? (
            <MdClose onClick={handleMenuClose} className={styles.exitMenu} />
          ) : (
            <MdMenu
              onClick={handleMenuOpen}
              className={styles.mobileMenuIcon}
            />
          )
        }
        <ul className={`${styles.navList} ${toggle ? styles.show : styles.hide}`}>
          <li className={styles.navItem}>
            <Link to="/" className={styles.navLink}>Home</Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/About-us" className={styles.navLink}>About</Link>
          </li>
          <li className={styles.navItem}>
            <select className={styles.navLink} value={selectedService} onChange={handleServiceChange}>
              <option value="/Services">Services</option>
              <option value="/Services/Crypto_Currency_Investment">Crypto Currency</option>
              <option value="/Services/Forex_Trading">Forex Trading</option>
              <option value="/Services/Digital_Real_Estates">Digital Real Estate</option>
            </select>
          </li>
          <li className={styles.navItem}>
            <Link to="/How-it-works" className={styles.navLink}>How it works</Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/Testimonials" className={styles.navLink}>Testimonials</Link>
          </li>
          <li className={styles.navItem}>
            {isAuth ? (
              <Link to="/Services" className={`${styles.navLink} ${styles.navLinker}`}>Invest Now</Link>
            ) : (
              <Link to="/register" className={styles.navLink}>Register</Link>
            )}
          </li>
          <li className={styles.navItem}>
            {
              isAuth ? (
                <button type="button" className={styles.navLink} onClick={handleLogout}>Logout</button>
              ) : (
                <Link to="/login" className={styles.navLink}>Login</Link>
              )
            }
          </li>
        </ul>
      </nav>

      <Outlet />
    </header>
  );
};

export default Nav;
