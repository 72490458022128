/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './CryptoPlan.module.scss';
import { estate } from '../../Data/Invest';
import investPlan from '../../redux/reducer/investAction';
import { fetchWallet } from '../../redux/reducer/walletAction';

const RealEstate = () => {
  const [showCards, setShowCards] = useState(3);
  const [showAll, setShowAll] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [principalAmount, setPrincipalAmount] = useState(0);
  const [currency, setCurrency] = useState('BTC');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePrincipalAmount = (e) => {
    setPrincipalAmount(e.target.value);
  };

  const handleCurrency = () => {
    setCurrency('BTC');
  };

  const submitInvestmentPlan = (e) => {
    e.preventDefault();
    if (selectedPlan) {
      dispatch(investPlan({
        planName: selectedPlan.plan,
        principalAmount: Number(principalAmount),
        interestRate: selectedPlan.percent,
        period: selectedPlan.period,
        min: selectedPlan.min,
        max: selectedPlan.max,
        currency,
      }));
      dispatch(fetchWallet());
      setSelectedPlan(null);
    }
    navigate('/Services');
  };

  const handleSeeAll = () => {
    setShowAll(true);
  };

  const handleOpen = (plan) => {
    setSelectedPlan(plan);
  };

  const handleCollapse = () => {
    setShowAll(false);
    setShowCards(3);
  };

  const formattedBalance = (balance) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return formatter.format(balance);
  };

  return (
    <article className={styles.proContainer}>
      {estate.slice(0, showAll ? estate.length : showCards).map((plan) => (
        <motion.article
          className={styles.plan}
          key={plan.plan}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 1.1 }}
        >
          <div className={styles.header}>
            <h3 className={styles.heading}>{plan.plan}</h3>
            <p className={styles.text}>{`${plan.percent}%`}</p>
            <p className={styles.text}>{`After ${plan.period} ${plan.period > 1 ? 'Days' : 'Day'}`}</p>
          </div>
          <h4 className={styles.subtitle}>What You Get</h4>
          <p className={styles.text}>
            <span className={styles.bold}>Minimum Deposit - </span>
            {formattedBalance(plan.min)}
          </p>
          <p className={styles.text}>
            <span className={styles.bold}>Maximum Deposit - </span>
            {plan.max === Infinity ? 'Unlimited' : formattedBalance(plan.max)}
          </p>
          <p className={styles.text}>{plan.sec}</p>
          <p className={styles.text}>{plan.sup}</p>
          <p className={styles.text}>{plan.pay}</p>
          <button type="button" onClick={() => handleOpen(plan)} className={styles.btn}>
            Invest Now
          </button>
          <AnimatePresence>
            {selectedPlan && selectedPlan.plan === plan.plan && (
              <motion.div
                initial={{ opacity: 0, x: '-100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '-100%' }}
                className={styles.modal}
              >
                <div className={styles.modalContent}>
                  <span className={styles.close} onClick={() => setSelectedPlan(null)}>&times;</span>
                  <h3 className={styles.modalHeading}>Investment Plan</h3>
                  <form className={styles.form} onSubmit={submitInvestmentPlan}>
                    <input type="text" value={plan.plan} className={styles.input} disabled required />
                    <input
                      type="number"
                      onChange={handlePrincipalAmount}
                      value={principalAmount}
                      min={plan.min}
                      max={plan.max !== 'Unlimited' ? plan.max : undefined}
                      placeholder="Enter Principal Amount"
                      className={styles.input}
                      required
                    />
                    <input type="text" value={currency} onChange={handleCurrency} className={styles.input} disabled required />
                    <input type="number" value={plan.period} className={styles.input} disabled required />
                    <input type="number" value={plan.percent} className={styles.input} disabled required />
                    <button type="submit" className={styles.btn}>Invest Now</button>
                  </form>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.article>
      ))}
      {!showAll && (
        <button type="button" className={styles.seeMore} onClick={handleSeeAll}>
          See Other Plans
        </button>
      )}
      {showAll && (
        <button type="button" className={styles.seeMore} onClick={handleCollapse}>
          See Less
        </button>
      )}
    </article>
  );
};

export default RealEstate;
