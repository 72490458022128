const how = [
  {
    id: '01',
    call: 'Register',
    res: 'Sign up for an account to get started on your wealth-building journey. It only takes a few minutes to create an account and gain access to our range of investment opportunities.',
  },
  {
    id: '02',
    call: 'Choose an Investment Plan',
    res: "Explore our diverse range of investment options designed to help you achieve your financial goals. Choose a plan that aligns with your investment goals and risk tolerance. Whether you're seeking short-term gains or long-term growth, we have a plan for you.",
  },
  {
    id: '03',
    call: 'Make a Deposit',
    res: 'Fund your account with the amount you want to invest. We accept a variety of cryptocurrency payment methods, including BNB, USDT, BTC, and ETH.',
  },
  {
    id: '04',
    call: 'Monitor Your Investment',
    res: 'Track the performance of your investment and watch your wealth grow. You can monitor your investment from your dashboard and make withdrawals at any time.',
  },
  {
    id: '05',
    call: 'Withdraw Profits',
    res: 'Withdraw your profits at any time. We process withdrawal requests within 24 hours, and you can access your funds through your preferred payment method.',
  },
  {
    id: '06',
    call: 'Refer & Earn',
    res: 'Invite your friends and family to join our platform and earn a commission on their investments. Our referral program is designed to reward you for helping us grow.',
  },
];

export default how;
