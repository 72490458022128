/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import Layout from './Router/Layout';
import { fetchWallet } from './redux/reducer/walletAction';
import { fetchCompletedTrades, fetchPendingTrades, fetchFailedTrades } from './redux/reducer/tradeAction';
import { fetchDeposits, fetchWithdrawals, fetchInvestments } from './redux/reducer/transactionAction';
import Ask from './Home/Ask';
import GlobalNotification from './Notification/Notification';
// import TradingViewWidget from './Components/Live/Update';

function App() {
  const dispatch = useDispatch();

  dispatch(fetchWallet());
  dispatch(fetchDeposits());
  dispatch(fetchCompletedTrades());
  dispatch(fetchPendingTrades());
  dispatch(fetchFailedTrades());
  dispatch(fetchWithdrawals());
  dispatch(fetchInvestments());

  return (
    <>
      <Layout />
      <Ask />
      <GlobalNotification />
    </>
  );
}

export default App;
