const crypto = [
  {
    plan: 'Starter Plan',
    percent: 2,
    period: 1,
    min: 200,
    max: 499,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Enthusiast Plan',
    percent: 5,
    period: 1,
    min: 500,
    max: 4999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Master Plan',
    percent: 10,
    period: 1,
    min: 5000,
    max: 9999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Pro Plan',
    percent: 30,
    period: 1,
    min: 10000,
    max: 49999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'VIP Plan',
    percent: 48,
    period: 3,
    min: 50000,
    max: 99999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Platinum Plan',
    percent: 50,
    period: 7,
    min: 100000,
    max: Infinity,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
];

const estate = [
  {
    plan: 'Starter Plan',
    percent: 2,
    period: 30,
    min: 200,
    max: 499,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Enthusiast Plan',
    percent: 5,
    period: 30,
    min: 500,
    max: 4999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Master Plan',
    percent: 50,
    period: 180,
    min: 5000,
    max: 9999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Pro Plan',
    percent: 200,
    period: 1095,
    min: 10000,
    max: 49999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'VIP Plan',
    percent: 300,
    period: 1825,
    min: 50000,
    max: 99999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Platinum Plan',
    percent: 500,
    period: 3650,
    min: 100000,
    max: Infinity,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
];

const forex = [
  {
    plan: 'Starter Plan',
    percent: 2,
    period: 1,
    min: 200,
    max: 499,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Enthusiast Plan',
    percent: 5,
    period: 1,
    min: 500,
    max: 4999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Master Plan',
    percent: 10,
    period: 1,
    min: 5000,
    max: 9999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Pro Plan',
    percent: 48,
    period: 1,
    min: 10000,
    max: 49999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'VIP Plan',
    percent: 30,
    period: 3,
    min: 50000,
    max: 99999,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
  {
    plan: 'Platinum Plan',
    percent: 50,
    period: 7,
    min: 100000,
    max: Infinity,
    sec: 'Enhanced Security',
    sup: '24/7 Support',
    pay: 'Instant Payment',
  },
];

export { crypto, estate, forex };
