import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CiWallet } from 'react-icons/ci';
import { MdClose } from 'react-icons/md';
import { BitcoinPaymentButton } from 'react-bitcoin-payment-button';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Wallet.module.scss';
import Nav from '../../Nav/Nav';
import PrimaryBtn from '../../Components/Button/PrimaryBtn';

const Wallet = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      delay: 300,
    });
  }, []);

  const navigate = useNavigate();

  const wallet = useSelector((state) => state.wallet.wallet);

  const formattedBalance = (balance) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(balance);
  };

  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleWithdrawal = () => {
    navigate('/dashboard/withdraw');
  };

  return (
    <section className={styles.container}>
      <Nav />
      <section className={styles.wallet}>
        <article className={styles.title}>
          <p className={styles.subtitle}>
            <CiWallet className={styles.icon} />
            Wallet
          </p>
        </article>
        <article className={styles.walletContent} data-aos-delay="1000" data-aos="zoom-out">
          <p className={styles.walletTitle}>Wallet Balance</p>
          <p className={styles.walletAmount} data-aos="zoom-in">
            {formattedBalance(wallet.totalBalance)}
          </p>
        </article>
        <article className={styles.walletSummary} data-aos-delay="1000" data-aos="zoom-out">
          <h6 className={styles.walletSummaryTitle}>Account Overview</h6>
          <table className={styles.walletTable}>
            <thead>
              <tr>
                <th>Assets</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr data-aos="fade-left">
                <td>USDT</td>
                <td>{formattedBalance(wallet.balance.USDT)}</td>
              </tr>
              <tr data-aos="fade-left">
                <td>BTC</td>
                <td>{formattedBalance(wallet.balance.BTC)}</td>
              </tr>
              <tr data-aos="fade-left">
                <td>ETH</td>
                <td>{formattedBalance(wallet.balance.ETH)}</td>
              </tr>
              <tr data-aos="fade-left">
                <td>BNB</td>
                <td>{formattedBalance(wallet.balance.BNB)}</td>
              </tr>
            </tbody>
          </table>
        </article>
        <article className={styles.btnDiv}>
          <PrimaryBtn text="Fund Account" action={handleToggle} />
          <PrimaryBtn text="Withdraw" action={handleWithdrawal} />
        </article>
      </section>
      {toggle && (
        <section className={styles.popWallet}>
          <MdClose onClick={handleToggle} className={styles.exitMenu} />
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$100</p>
            <BitcoinPaymentButton
              uid="0310b1d1036c4286"
              className={styles.icon}
            />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$500</p>
            <BitcoinPaymentButton uid="5c4704155b004bde" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$1,000</p>
            <BitcoinPaymentButton uid="20b5620fc54b4596" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$5,000</p>
            <BitcoinPaymentButton uid="5205ed700ea14de3" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$10,000</p>
            <BitcoinPaymentButton uid="d9d0087965f84a65" className={styles.icon} />
          </article>
          <article className={styles.popWalletContent}>
            <p className={styles.popWalletTitle}>$20,000</p>
            <BitcoinPaymentButton uid="1dfe3c3efe04486b" className={styles.icon} />
          </article>
        </section>
      )}
    </section>
  );
};

export default Wallet;
