import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Recommendation.module.scss';
import reviews from '../Data/Review';

const Recommendation = () => {
  const configure = () => ({
    extraLarge: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },

    desktop: {
      breakpoint: { max: 3000, min: 956 },
      items: 2,
    },

    tablet: {
      breakpoint: { max: 955, min: 768 },
      items: 1,
    },

    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  });

  return (
    <section className={styles.container}>
      <article className={styles.header}>
        <h2 className={styles.title}>What Our Clients Say</h2>
        <div className={styles.underline} />

        <p className={styles.subtitle}>
          Our delivery match our promise and that is why they all have these to say about us:
        </p>
      </article>

      <article className={styles.reviewDiv}>
        <Carousel
          autoPlay
          autoPlaySpeed={5000}
          infinite
          showArrows={false}
          showDots
          className={styles.carousel}
          keyBoardControl
          responsive={configure()}
        >
          {reviews.map((review) => (
            <Card className={styles.reviewCard} key={review.id}>
              <Card.Body className={styles.reviewInfo}>
                <Card.Img variant="top" src={review.image} className={styles.reviewImage} />
                <Card.Body>
                  <Card.Title className={styles.reviewName}>{review.name}</Card.Title>
                  <div className={styles.spanner} />
                  <Card.Text className={styles.location}>{review.title}</Card.Text>
                </Card.Body>
              </Card.Body>
              <Card.Text className={styles.reviewText}>{review.text}</Card.Text>
            </Card>
          ))}
        </Carousel>
      </article>
    </section>
  );
};

export default Recommendation;
