import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import baseUrl from '../baseUrl';

const investPlan = createAsyncThunk(
  'invest/plan',
  async ({
    planName, principalAmount, interestRate, period, min, max, currency,
  }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token, authorization denied');
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(`${baseUrl}/invest`, {
        planName, principalAmount, interestRate, period, min, max, currency,
      }, config);
      toast.success('Investment submitted successfully');
      return data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.message);
    }
  },
);

export default investPlan;
