import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import styles from './Index.module.scss';
import tez from '../images/tez.png';
import video from '../images/bitcoin.mp4';
import PrimaryBtn from '../Components/Button/PrimaryBtn';
import web from '../images/web.mp4';

const Index = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const title = ['Crypto', 'Real Estate', 'Forex', 'Steady', 'Secure', 'Low Risk'];
  const [index, setIndex] = useState(0);
  const [clicked, setClicked] = useState(false);

  const handleLogin = () => {
    if (isAuthenticated) {
      navigate('/Services');
    } else {
      navigate('/register');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % title.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [title.length]);

  const handleVideoClose = (e) => {
    e.preventDefault();
    setClicked(!clicked);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setClicked(!clicked);
  };

  return (
    <section className={styles.main} style={{ overflow: 'hidden' }}>
      <video autoPlay muted loop className={styles.videoBg}>
        <source src={web} type="video/mp4" />
      </video>
      <h1 className={styles.title} data-aos="fade-down-right">
        Grow Your Wealth with Simplified
        <span className={styles.tint} data-aos="flip-up">
          {' '}
          {title[index]}
          {' '}
        </span>
        Investments.
      </h1>
      <p className={styles.text} data-aos="zoom-in-up">
        Secure.
        <span className={styles.tint}> Transparent. </span>
        Profitable.
      </p>
      <p className={styles.text}>
        Start your journey towards financial freedom today.
      </p>
      <article className={styles.btnDiv}>
        <PrimaryBtn text="Start Investing Today" action={handleLogin} />
        <button type="button" className={styles.unlock} onClick={handleClick}>Watch Video</button>
        {clicked && (
          <div className={styles.videoContainer}>
            <MdClose
              onClick={(e) => {
                handleVideoClose(e);
              }}
              className={styles.exitMenu}
            />
            <video className={styles.video} controls>
              <source src={video} type="video/mp4" />
              <track kind="captions" srcLang="en" />
            </video>
          </div>
        )}
      </article>
      <img src={tez} alt="tez" className={styles.tez} />
    </section>
  );
};

export default Index;
