/* eslint-disable arrow-body-style */
/* eslint-disable import/extensions */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Home/Home';
import Login from '../Form/Login';
import Register from '../Form/Register';
import About from '../About/About';
import Service from '../Services/Service';
import Crypto from '../Services/Crypto/Crypto';
import Stock from '../Services/Forex/Forex';
import Real from '../Services/Real/RealEstate';
import How from '../How/How';
import Testimonial from '../Testimonial/Testimonial';
import FAQs from '../Faqs/Faqs';

const UnAuth = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/About-us" element={<About />} />
      <Route path="/Services" element={<Service />} />
      <Route path="/Services/Crypto_Currency_Investment" element={<Crypto />} />
      <Route path="/Services/Forex_Trading" element={<Stock />} />
      <Route path="/Services/Digital_Real_Estates" element={<Real />} />
      <Route path="/How-it-works" element={<How />} />
      <Route path="/Testimonials" element={<Testimonial />} />
      <Route path="/Faqs" element={<FAQs />} />
    </Routes>
  );
};

export default UnAuth;
