import secure from '../images/icons/secure.svg';
import withdrawal from '../images/icons/withdrawal.svg';
import returns from '../images/icons/returns.svg';
import invest from '../images/icons/flexible.svg';
import support from '../images/icons/support.svg';

const choose = [
  {
    id: 1,
    icon: secure,
    title: 'Secure Investments',
    text: 'Your security is our top priority. Benefit from industry-leading encryption and robust security measures to safeguard your assets.',
  },
  {
    id: 2,
    icon: withdrawal,
    title: 'Instant Withdrawal',
    text: 'Choose from short-term, medium-term, and long-term investment plans tailored to your investment horizon',
  },
  {
    id: 3,
    icon: returns,
    title: 'Competitive Returns',
    text: 'Benefit from attractive returns on your investments, carefully curated to maximize profitability while managing risk.',
  },
  {
    id: 4,
    icon: invest,
    title: 'Flexible Investment Plans',
    text: 'Choose from short-term, medium-term, and long-term investment plans tailored to your investment horizon',
  },
  {
    id: 5,
    icon: support,
    title: '24/7 Customer Support',
    text: 'Our dedicated support team is available around the clock to assist you with any questions or concerns you may have.',
  },
];

export default choose;
