/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Offer.module.scss';
import offer from '../../Data/Offer';

const Offer = () => {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-in',
      once: true,
    });
  }, []);

  const checkLink = (index) => {
    let link;
    if (index === 0) {
      link = 'Services/Crypto_Currency_Investment';
    } else if (index === 1) {
      link = 'Services/Forex_Trading';
    } else {
      link = 'Services/Digital_Real_Estates';
    }
    return link;
  };

  return (
    <section className={styles.container} style={{ overflow: 'hidden' }}>
      <article className={styles.header}>
        <h2 className={styles.title}>What We Offer</h2>
        <div className={styles.underline} />
      </article>

      <article className={styles.content}>
        {offer.map((perk, index) => {
          return (
            <Link to={`/${checkLink(index)}`} data-aos="zoom-out-right" key={perk.id} className={styles.linker}>
              <article className={styles.perk}>
                <img src={perk.img} className={styles.icon} alt={perk.title} />
                <div className={styles.div}>
                  <h5 className={styles.heading}>{perk.topic}</h5>
                  <p className={styles.text}>{perk.intro}</p>
                </div>
              </article>
            </Link>
          );
        })}
      </article>
    </section>
  );
};

export default Offer;
