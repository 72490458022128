/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Btn.module.scss';

const SecBtn = ({ text, action }) => {
  return (
    <button type="button" className={styles.secBtn} onClick={action}>{text}</button>
  );
};

SecBtn.defaultProps = {
  text: 'Click me',
  action: () => {},
};

SecBtn.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func,
};

export default SecBtn;
